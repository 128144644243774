<template>
  <svg
    viewBox="2261.5 879.85 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2301.5,483.81a40,40,0,1,0,40,40A40,40,0,0,0,2301.5,483.81ZM2312.15,504l8.33,3.16-1.56,4.09-8.34-3.16Zm-21.3,0,1.56,4.09L2284,511.2l-1.56-4.09Zm-13.74,19.83a7.83,7.83,0,0,1-.81-3.53,8.52,8.52,0,0,1,.5-2.81h14.92a7.49,7.49,0,0,1,.5,2.81,7.92,7.92,0,0,1-.81,3.53ZM2292,546l2.46-2.87a9.23,9.23,0,0,1,14,0L2311,546Zm33.84-22.2h-14.3a7.83,7.83,0,0,1-.81-3.53,8.52,8.52,0,0,1,.5-2.81h14.93a8.07,8.07,0,0,1-.32,6.34Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
