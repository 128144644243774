<template>
  <svg
    viewBox="366.45 1102.92 95.28 96.59"
    width="95.28"
    height="96.59"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M382.72,803.47a13.58,13.58,0,1,1,13.58-13.58A13.6,13.6,0,0,1,382.72,803.47Zm0-23.58a10,10,0,1,0,10,10A10,10,0,0,0,382.72,779.89Zm8,10h-3.58a4.46,4.46,0,0,1-4.45,4.45v3.58A8,8,0,0,0,390.75,789.89Zm60.52-62.07a10.47,10.47,0,1,1,10.49-10.45A10.51,10.51,0,0,1,451.27,727.82Zm0-17.35a6.91,6.91,0,1,0,6.9,6.9A6.91,6.91,0,0,0,451.27,710.47Zm-59.8,21.35a8.13,8.13,0,1,1,8.11-8.15A8.13,8.13,0,0,1,391.47,731.82Zm0-12.67a4.53,4.53,0,1,0,4.53,4.52A4.54,4.54,0,0,0,391.47,719.15ZM372.68,776.3a6.23,6.23,0,1,1,6.23-6.22A6.23,6.23,0,0,1,372.68,776.3Zm0-8.86a2.64,2.64,0,1,0,2.64,2.64A2.62,2.62,0,0,0,372.68,767.44Zm51.39-34.37a27.28,27.28,0,1,0,27.28,27.27A27.29,27.29,0,0,0,424.07,733.07Zm-10.83,19.8a5.42,5.42,0,0,1,5.43,5.21H407.81A5.44,5.44,0,0,1,413.24,752.87Zm10.83,26a13.41,13.41,0,0,1-12.3-8.07l3.24-1.4a9.89,9.89,0,0,0,18.11,0l3.25,1.4A13.39,13.39,0,0,1,424.07,778.83Zm5.39-20.71a5.44,5.44,0,0,1,10.87,0Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
