<template>
  <svg
    viewBox="2245.769 1352.38 74.931 80.057"
    width="74.931"
    height="80.057"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2283,956.34a37.54,37.54,0,0,0-12.35,72.88,8.38,8.38,0,0,1,3.88,2.75,10.93,10.93,0,0,0,17.57,0,8.66,8.66,0,0,1,3.78-2.72A37.57,37.57,0,0,0,2283,956.34Zm-27,26.17a17.3,17.3,0,0,1,7.53-10.85,16.4,16.4,0,0,1,12.7-2l-.91,4a12.39,12.39,0,0,0-9.6,1.44,13.16,13.16,0,0,0-5.72,8.29Zm13.88,13.85c-3.56,0-6.47-3.34-6.47-7.47s2.91-7.48,6.47-7.48,6.47,3.35,6.47,7.48S2273.54,996.36,2269.94,996.36Zm13.35,33.14c-3.34,0-6.1-3.87-6.1-8.66s2.72-8.66,6.1-8.66,6.1,3.88,6.1,8.66S2286.64,1029.5,2283.29,1029.5Zm13.16-33.14c-3.56,0-6.47-3.34-6.47-7.47s2.91-7.48,6.47-7.48,6.48,3.35,6.48,7.48S2300.05,996.36,2296.45,996.36Zm10.07-12.94a13.24,13.24,0,0,0-5.72-8.29,12.37,12.37,0,0,0-9.6-1.44l-.91-4a16.38,16.38,0,0,1,12.7,2,17.38,17.38,0,0,1,7.53,10.85Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
