<template>
  <svg
    viewBox="529.33 654.73 99.73 97.4"
    width="99.73"
    height="97.4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M614.84,279.38l-2.76-3.27,14.22-12,2.76,3.27Zm-63-5.45-13.59-15.24-3.2,2.85,13.6,15.23Zm66.16,10,1.87,3.82,8.72-4.29-1.87-3.82Zm.26,33.82a38.34,38.34,0,1,1-38.34-38.34A38.35,38.35,0,0,1,618.26,317.72Zm-60.89-3.38,15.75-5.95a8.44,8.44,0,0,0-15.75,5.95Zm41.86,11.31H560.6a19.71,19.71,0,0,0,38.63,0ZM597.33,304a8.47,8.47,0,0,0-10.62,4.33l15.76,5.95A8.48,8.48,0,0,0,597.33,304Zm-55.07-20.2-11.34-4.56-1.59,4,11.33,4.56Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
