/* eslint-disable prettier/prettier */
import { createRouter, createWebHistory } from '@ionic/vue-router'
import Login from '@/views/auth/Login.vue'
import Tabs from '@/views/Tabs.vue'

const routes = [
  {
    path: '/',
    redirect: '/tabs/home',
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/home',
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'gedachten',
        name: 'gedachten',
        component: () => import('@/views/Gedachten.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      // {
      //   path: "gedachten/add",
      //   component: () => import("@/views/gedachten/Gedachten_add.vue"),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
      {
        path: 'dankbaar',
        name: 'dankbaar',
        component: () => import('@/views/Dankbaar.vue'),
        meta: {
          requiresAuth: true,
          title: 'Dankbaar',
        },
      },
      {
        path: 'tips',
        name: 'tips',
        component: () => import('@/views/Tips.vue'),
        meta: {
          requiresAuth: true,
          title: 'Tips',
        },
      },
      // {
      //   path: "tips/add",
      //   component: () => import("@/views/tips/Add.vue"),
      //   meta: {
      //     requiresAuth: true,
      //     requiresAdmin: true,
      //     title: "Tips",
      //   },
      // },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/profile',
        name: 'settingsProfile',
        component: () => import('@/views/settings/Profile.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/security',
        name: 'settingsSecurity',
        component: () => import('@/views/settings/Security.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/contact',
        name: 'settingsContact',
        component: () => import('@/views/settings/Contact.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings/admin',
        name: 'settingsAdmin',
        component: () => import('@/views/settings/Admin.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: 'settings/admin/tips/',
        name: 'settingsAdminTips',
        component: () => import('@/views/settings/tips/Main.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: 'settings/admin/tips/add',
        name: 'settingsAdminTipsAdd',
        component: () => import('@/views/settings/tips/Add.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: 'settings/admin/stats/',
        name: 'settingsAdminStats',
        component: () => import('@/views/settings/stats/Main.vue'),
        meta: {
          requiresAuth: false,
          requiresAdmin: false,
        },
      },
      {
        path: 'settings/admin/feedback/',
        name: 'settingsAdminFeedback',
        component: () => import('@/views/settings/feedback/Main.vue'),
        meta: {
          requiresAuth: false,
          requiresAdmin: false,
        },
      },
      {
        path: 'settings/admin/email',
        name: 'settingsAdminEmail',
        component: () => import('@/views/settings/email/Test.vue'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/auth/Forgot.vue'),
  },
  {
    path: '/forgot/send',
    name: 'forgotSend',
    component: () => import('@/views/auth/ForgotSend.vue'),
  },
  {
    path: '/forgot/reset',
    name: 'forgotReset',
    component: () => import('@/views/auth/ForgotReset.vue'),
  },
  {
    path: '/forgot/verify/:email',
    name: 'forgotVerify',
    component: () => import('@/views/auth/ForgotVerifyCode.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import('@/views/auth/confirmEmail.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active-link',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
