<template>
  <svg
    viewBox="1809.305 899.782 126.035 76.148"
    width="126.035"
    height="76.148"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1935.34,539.39a7.06,7.06,0,0,0-2.21-5.12,8.59,8.59,0,0,0-10.58-8.85,8.65,8.65,0,0,0-6.55,8.12l-7,1.82a38,38,0,0,0-74.85-.4l-5.47-1.42a8.63,8.63,0,0,0-6.54-8.12,8.41,8.41,0,0,0-6.5,1,8.51,8.51,0,0,0-3.88,5.32,7.1,7.1,0,0,0-.2,2.51,7,7,0,0,0,3.59,12.05v.4a6.74,6.74,0,0,0,2.12,4.87,6.23,6.23,0,0,0,4.33,1.77h.29a6.88,6.88,0,0,0,6.35-5.27l5.66-1.42a38,38,0,0,0,75.45-.4l7.23,1.82a6.72,6.72,0,0,0,6.35,5.27h.29a6.22,6.22,0,0,0,4.34-1.77,6.85,6.85,0,0,0,2.11-4.87v-.4A7.09,7.09,0,0,0,1935.34,539.39Zm-110.14,6-.1,1.18c0,.15-.34,3.29-3.39,3.44a2.81,2.81,0,0,1-2.17-.83,3.47,3.47,0,0,1-1.08-2.51,2.72,2.72,0,0,1,0-.64,7.06,7.06,0,0,0,4.18-3.6l-3-1.47a3.75,3.75,0,0,1-2.76,2,2.35,2.35,0,0,1-.59,0,3.73,3.73,0,0,1-3.74-3.74,3.68,3.68,0,0,1,2.61-3.54l-.3-1a5.74,5.74,0,0,1,0-2.37,5.25,5.25,0,0,1,10.29,2l-.25,1.48,8.61,2.21c-.09,1.14-.14,2.32-.14,3.5,0,.49,0,1,0,1.52Zm18.85-5.91a25,25,0,0,1,13.68-4.38,16.6,16.6,0,0,0-10.53-2.46l-.54-3.54c.44-.05,10.87-1.58,17.07,6.89l-1.67,2.85c-9.69-1.13-15.75,3.4-15.8,3.45Zm39,27.76h-23a3.55,3.55,0,0,1-3.54-3.89l0-.3a15.07,15.07,0,0,1,30,0l0,.3A3.59,3.59,0,0,1,1883,567.25Zm15-26.63-1.09,1.43h0c-.14-.1-6.2-4.58-15.79-3.45l-1.68-2.85c6.2-8.46,16.64-6.94,17.08-6.89l-.54,3.54a16.2,16.2,0,0,0-10.58,2.46,25.36,25.36,0,0,1,13.68,4.38Zm30.31,2.51a2.39,2.39,0,0,1-.59,0,3.73,3.73,0,0,1-2.75-2l-3,1.47a7.07,7.07,0,0,0,4.19,3.59,2.6,2.6,0,0,1,0,.64,3.46,3.46,0,0,1-1.09,2.51,2.78,2.78,0,0,1-2.16.84c-3-.15-3.4-3.35-3.4-3.44l-.1-1.18-9.94-2.56c0-.4,0-.74,0-1.14,0-1,0-2.06-.15-3.1l10.19-2.6-.25-1.48a5.33,5.33,0,0,1,3.94-5.91,5.21,5.21,0,0,1,4,.64,5.29,5.29,0,0,1,2.36,3.25,5.38,5.38,0,0,1,0,2.36l-.3,1a3.67,3.67,0,0,1-1.13,7.18Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
