<template>
  <svg
    viewBox="2244.82 1122.09 83.44 83.44"
    width="83.44"
    height="83.44"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2286.54,726.05a41.72,41.72,0,1,0,41.72,41.72A41.72,41.72,0,0,0,2286.54,726.05Zm16.8,67.17-6-4.71-7.2,5.68-7.21-5.68-7.2,5.68-8.46-6.64,2.47-3.12,6,4.71,7.2-5.67,7.21,5.67,7.2-5.67,8.46,6.63Zm-40-28.61a7.33,7.33,0,1,1,7.33,7.33A7.35,7.35,0,0,1,2263.34,764.61Zm39.08,7.33a7.34,7.34,0,1,1,7.33-7.33A7.35,7.35,0,0,1,2302.42,771.94Zm11-17.4c-2.61-2.25-7.2-4.86-11.73-4.86h-.07a14.22,14.22,0,0,0-12.74,7.86h-4.08a14.21,14.21,0,0,0-12.74-7.89c-4.37,0-9.88,2.51-12.06,4.72l-3.26-3.19c2.93-3,9.45-6.13,15.32-6.13a18.77,18.77,0,0,1,14.8,7.17,18.85,18.85,0,0,1,14.76-7.17c5.8,0,11.44,3.07,14.8,6Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
