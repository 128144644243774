import firebase from "firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/storage";
//import "@firebase/analytics";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyC0grKdLRTMb6I16B6EQZAmxm-r3nAspAY",
  authDomain: "gedachtegrip.firebaseapp.com",
  projectId: "gedachtegrip",
  storageBucket: "gedachtegrip.appspot.com",
  messagingSenderId: "964314435863",
  appId: "1:964314435863:web:cabf172392083d6dc6a752",
  measurementId: "G-JVQ4X2Z7JV",
};
firebase.initializeApp(firebaseConfig);
//firebase.analytics();

// utils
const db = firebase.firestore();
const firestore = firebase.firestore;
const auth = firebase.auth();
const storage = firebase.storage();

// collection references
const usersCollection = db.collection("users");

// export utils/refs
export { firebase, db, firestore, auth, storage, usersCollection };
