<template>
  <svg
    viewBox="954.7 652.82 87.919 101.186"
    width="87.919"
    height="101.186"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M968.93,268.06l-2.12-.56,1.94-1.05a11.87,11.87,0,0,0,5.9-7.54l.56-2.13,1,1.94a12.22,12.22,0,0,0,7.55,5.9l2.13.56L984,266.23a12.24,12.24,0,0,0-5.9,7.54l-.56,2.13-1-1.94A12.23,12.23,0,0,0,968.93,268.06Zm36.31-3.19a3.8,3.8,0,1,0-3.8-3.79A3.78,3.78,0,0,0,1005.24,264.87Zm-45,24.39a5.54,5.54,0,1,0,5.54,5.54A5.54,5.54,0,0,0,960.28,289.26Zm80.09,42.48a39.35,39.35,0,1,1-24-50.23A39.35,39.35,0,0,1,1040.37,331.74Zm-60.48-37.53,2.31,3.64a19.74,19.74,0,0,1,14.58-2l1.12-4.16A24,24,0,0,0,979.89,294.21Zm1.61,12.1,16.26,5.78a8.65,8.65,0,0,0-16.26-5.78Zm33.33,31.31L983,326.36a17.21,17.21,0,0,0,31.79,11.26Zm7.07-25.5a8.66,8.66,0,0,0-10.81,4.65l16.27,5.78A8.73,8.73,0,0,0,1021.9,312.12Zm14.83,2.17A24.2,24.2,0,0,0,1024.31,301l-1.74,3.92a20.3,20.3,0,0,1,10.1,10.74Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
