<template>
  <svg
    viewBox="238.84 1119.438 74.2 80.037"
    width="74.2"
    height="80.037"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M312.47,759.93H267.81c10.35-4.13,18.45-10.82,20.48-15.26,1.19-2.57.94-5-.68-6.7-2.66-2.75-8.26-2.81-14.67-.19-7,2.85-7.48,6.26-7.17,8a4.16,4.16,0,0,0,.94,2c-6.57,1.41-10,.28-10.79-1.15s.41-4.13,4.57-7.26A65.43,65.43,0,0,1,290.8,726.9c7.16-.84,12.73.5,14.17,3.47,1.53,3.16-1.19,8.54-7.73,15.2a1.62,1.62,0,1,0,2.31,2.26c7.79-8,10.58-14.3,8.32-18.9-2.15-4.34-8.66-6.31-17.48-5.25a68.9,68.9,0,0,0-31.84,13.07c-5.19,3.88-7.26,8.13-5.51,11.36,1.25,2.31,4.16,3.5,8.2,3.5a39.79,39.79,0,0,0,13-2.72,1.87,1.87,0,0,0,.59-.38,7.79,7.79,0,0,0,3.1-2.19c1.47-1.87.78-3.15.41-3.62-1.22-1.6-4.1-1.22-5.35-.66a1.61,1.61,0,1,0,1.31,2.94c.13-.06.31-.09.5-.16a7.44,7.44,0,0,1-4.53,1.32,1.33,1.33,0,0,1-1.25-1c-.16-.78.78-2.63,5.22-4.44,5.72-2.35,9.76-1.94,11.1-.57.69.69.69,1.76.07,3.13-2,4.44-14.3,14.26-29.56,16.61H239.41a36.12,36.12,0,0,0-.57,6.44,37.1,37.1,0,1,0,74.2,0A36,36,0,0,0,312.47,759.93Zm-51.92,16.89a9.69,9.69,0,0,1-7.91-4l3.41-2.38a5.65,5.65,0,0,0,4.59,2.25,6.32,6.32,0,0,0,5.23-2.35l3.28,2.54a10.55,10.55,0,0,1-8.35,3.94A.72.72,0,0,1,260.55,776.82Zm16,15.79a31.21,31.21,0,0,1-10.79-1.93l1.35-3.63a26.86,26.86,0,0,0,18.86,0l1.34,3.63A30.24,30.24,0,0,1,276.53,792.61Zm13.67-15.48H290a9.69,9.69,0,0,1-7.91-4l3.41-2.38a5.63,5.63,0,0,0,4.6,2.25,6.31,6.31,0,0,0,5.22-2.34l3.28,2.53A10.44,10.44,0,0,1,290.2,777.13Zm-31-52.92a3.41,3.41,0,1,1-3.45,3.41A3.41,3.41,0,0,1,259.21,724.21Zm-12.8,25.27a2.6,2.6,0,1,1-2.59,2.6A2.63,2.63,0,0,1,246.41,749.48Zm45.14-.25a4.1,4.1,0,1,1-4.1,4.1A4.11,4.11,0,0,1,291.55,749.23Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
