<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page>
    <ion-tabs ref="tabs">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          @click="logTabNavigate('home')"
          tab="home"
          href="/tabs/home"
        >
          <home class="tab_icon" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button
          @click="logTabNavigate('gedachten')"
          tab="gedachten"
          href="/tabs/gedachten"
        >
          <Brain class="tab_icon" />
          <ion-label>Gedachten</ion-label>
        </ion-tab-button>

        <ion-tab-button
          @click="logTabNavigate('dankbaar')"
          tab="dankbaar"
          href="/tabs/dankbaar"
        >
          <Yoga class="tab_icon" />
          <ion-label>Dankbaar</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tips" href="/tabs/tips">
          <heart class="tab_icon" />
          <ion-label>Blogs</ion-label>
        </ion-tab-button>

        <ion-tab-button
          @click="logTabNavigate('settings')"
          tab="settings"
          href="/tabs/settings"
        >
          <cog class="tab_icon" />
          <ion-label>Instellingen</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import { Home, Heart, Cog } from "mdue";
import Brain from "@/components/icons/BrainSolid.vue";
import Yoga from "@/components/icons/Yoga.vue";

export default {
  name: "Tabs",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
    Home,
    Heart,
    Cog,
    Yoga,
    Brain,
  },
  methods: {
    logTabNavigate(tab) {
      //this.$gtag.event("tabNavigate", { tab: tab });
      // this.$gtag.event("tabNavigate", {
      //   event_category: "navigate",
      //   event_label: tab,
      // });
      this.$router.push("/tabs/" + tab);
    },
  },
};
</script>

<style scoped>
.tab_icon {
  font-size: 24px;
}

.tab_icon_small {
  font-size: 18px;
}

.small_icon_box {
  height: 24px;
  width: 24px;
}

.small_icon_box svg {
  margin-top: 3px;
}

ion-label {
  font-size: 10px;
}
</style>
