<template>
  <svg
    viewBox="697.89 889.6 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M737.89,493.56a40,40,0,1,0,40,40A40,40,0,0,0,737.89,493.56Zm-28.37,33,2.22-3,4.1,3a7.83,7.83,0,0,1,6.79-3.85,8,8,0,0,1,8,7.67h-16Zm28.37,32.16a17.85,17.85,0,0,1-16.73-11.61h33.46A17.89,17.89,0,0,1,737.89,558.71Zm23.21-28.4H745.18a7.94,7.94,0,0,1,14.76-3.79l4.1-3,2.22,3Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
