<template>
  <svg
    viewBox="2399.946 880.239 85.635 79.06"
    width="85.635"
    height="79.06"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2469,516.36c0-3.27,2.51-8.25,4.85-12.15a39.53,39.53,0,1,0,4.94,23.64C2473.42,527.72,2469,522.61,2469,516.36Zm-53.47-3.57,14.63-5.71,1.57,4-14.63,5.74Zm7.18,18.4c-3.07-.43-5.14-3.8-4.61-7.51s3.47-6.35,6.55-5.91,5.14,3.81,4.61,7.51S2425.86,531.66,2422.75,531.19Zm17,19.11c-5.72,0-10.32-1.94-10.32-4.31s4.64-4.31,10.32-4.31S2450,543.62,2450,546,2445.43,550.3,2439.72,550.3Zm14.83-19.11c-3.18.51-6.22-2.1-6.78-5.81s1.53-7.11,4.67-7.61,6.22,2.1,6.78,5.81S2457.72,530.69,2454.55,531.19Zm7.11-14.36L2447,511.12l1.57-4,14.63,5.71Zm23.92-.47c0,4.38-2.91,7.92-6.48,7.92H2479c-3.54,0-6.45-3.57-6.45-7.92,0-3.44,3.71-9.85,6.48-13.79C2481.7,506.54,2485.58,511.69,2485.58,516.36Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
