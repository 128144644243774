<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->
  <ion-page>
    <ion-content
      class="bg-none ion-padding"
      :fullscreen="false"
      :scroll-y="true"
    >
      <div>
        <div class="my-auto">
          <img :src="require('@/assets/logo_small.png')" />
          <form class="w-100" @submit.prevent="submitForm()">
            <h1 class="pt-2">Login</h1>
            <!-- <p class="text-grey">Hi! Welkom terug</p> -->
            <ion-item :class="{ error: v$.form.email.$errors.length }">
              <ion-label color="primary" position="stacked"> Email </ion-label>
              <ion-input
                aria-label="Email"
                type="email"
                v-model="form.email"
                color="dark"
                placeholder="example@email.com"
              />
            </ion-item>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <div class="error-msg">
                <ion-icon color="danger" :icon="alertOutline"></ion-icon>
                {{ error.$message }}
              </div>
            </div>
            <ion-item :class="{ error: v$.form.password.$errors.length }">
              <ion-label color="primary" position="stacked">
                Wachtwoord
              </ion-label>
              <ion-input
                aria-label="Password"
                type="password"
                v-model="form.password"
                color="dark"
                placeholder="******"
                enterkeyhint="Login"
                @keyup.enter="submitForm()"
              />
            </ion-item>
            <div
              class="input-errors"
              v-for="(error, index) of v$.form.password.$errors"
              :key="index"
            >
              <div class="error-msg">
                <ion-icon color="danger" :icon="alertOutline"></ion-icon>
                {{ error.$message }}
              </div>
            </div>
            <ion-button
              @click.prevent="submitForm()"
              class="ion-margin-vertical"
              :disabled="isLoading"
              color="primary"
              expand="block"
            >
              Login <ion-spinner v-show="isLoading" />
            </ion-button>
          </form>
        </div>
        <div class="d-flex flex-row justify-content-between">
          <router-link color="primary" :to="{ name: 'forgot' }" class="mr-3">
            Wachtwoord vergeten?
          </router-link>
          <router-link class="text-align-right ml-3" :to="{ name: 'register' }">
            Gratis account aanmaken
          </router-link>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonPage,
  IonItem,
  IonInput,
  IonLabel,
  IonSpinner,
  IonIcon,
} from "@ionic/vue";

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import { alertOutline } from "ionicons/icons";
import { mapGetters } from "vuex";
import { openToast, closeToast } from "@/common";

export default {
  name: "Login",
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
      },
      v$: useVuelidate(),
      alertOutline,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "auth/isLoggedIn" }),
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage(
            "We hebben je emailadres nodig",
            required
          ),
          email: helpers.withMessage("Dit is geen geldig emailadres", email),
        },
        password: {
          required: helpers.withMessage(
            "Je hebt een wachtwoord nodig",
            required
          ),
          minLength: helpers.withMessage(
            "Je wachtwoord moet minimaal 6 tekens zijn",
            minLength(6)
          ),
        },
      },
    };
  },
  components: {
    IonButton,
    IonContent,
    IonPage,
    IonItem,
    IonInput,
    IonLabel,
    IonIcon,
    IonSpinner,
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$store.dispatch("auth/logout", false);
    }
  },
  methods: {
    submitForm() {
      this.isLoading = true;
      this.v$.$validate().then((isFormCorrect) => {
        if (!isFormCorrect) {
          this.isLoading = false;
          return;
        }
        this.$store
          .dispatch("auth/login", {
            email: this.form.email,
            password: this.form.password,
          })
          .then(
            () => {
              this.isLoading = false;
              this.$router.replace({ name: "home" });
            },
            (error) => {
              console.error(error);
              openToast(error, "danger").then(() => {
                this.isLoading = false;
              });
            }
          );
      });
    },
    resetData() {
      this.isLoading = false;
      this.form.email = "";
      this.form.password = "";
      this.v$.$reset();
    },
  },
  ionViewWillLeave() {
    this.resetData();
    closeToast();
  },
};
</script>

<style scoped>
p.text-grey {
  color: var(--ion-color-medium);
}

img {
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

ion-item {
  --padding-start: 0;
}

ion-content > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
