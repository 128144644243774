<template>
  <svg
    viewBox="2106 1126.45 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2146,730.41a40,40,0,1,0,40,40A40,40,0,0,0,2146,730.41Zm-25.66,55.11c-2.41,0-4.37-2.28-4.37-5.12s4.37-8.93,4.37-8.93,4.37,6.09,4.37,8.93S2122.75,785.52,2120.35,785.52Zm2.09-19.36h0l-3-6.84h0l19.83-8.62,1.75,4-7.87,3.44a6.86,6.86,0,0,1-4.43,12.11A6.94,6.94,0,0,1,2122.44,766.16Zm34.63,28.73a12.6,12.6,0,0,0-22.11,0l-5.24-2.88a18.61,18.61,0,0,1,32.66,0Zm15.49-35.57-3,6.81h0a6.87,6.87,0,1,1-10.71-8l-7.84-3.37,1.75-4,19.8,8.58Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
