<template>
  <svg
    viewBox="249.732 1342.9 72.723 86.77"
    width="72.723"
    height="86.77"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M286.05,946.89a36.33,36.33,0,0,0-7.38,71.9,9.46,9.46,0,0,1-1.5-1.32,4.25,4.25,0,0,1-.81-4.44,6.53,6.53,0,0,1,6.27-3.93,11.13,11.13,0,0,1,6.13,2,18.44,18.44,0,0,1,1.6,1.25c.2.2.5.44.67.58a1.76,1.76,0,0,0,.34-.17c.24-.14.61-.61,1.46-3.36a15.57,15.57,0,0,1,3.76-6.43,10.17,10.17,0,0,1,7-3.16h.27c3.05,0,5.35,1.53,6.17,4.07a6,6,0,0,1-1.9,6.54,13.54,13.54,0,0,0-2.17,2,1,1,0,0,1,.17.21c.07.06.1.13.17.2a4.42,4.42,0,0,1,.67.13,36.34,36.34,0,0,0-20.87-66.1Zm-10.27,32.59c0,.11.07.17.11.27a6.25,6.25,0,0,1-12,3.63c0-.1,0-.17-.06-.27l-2.34.71-1-3.25,15.75-4.75,1,3.26ZM299.23,958l-.06,3.39c-6.58-.1-10.61,3.79-12,7.18L284,967.22A15.83,15.83,0,0,1,299.23,958ZM283,1000.76a33,33,0,0,1-3.42-.2l.64-5.29a19.57,19.57,0,0,0,17.69-6.57l4,3.46A24.2,24.2,0,0,1,283,1000.76Zm16-21.45a6.25,6.25,0,1,1,4.16-7.79A6.22,6.22,0,0,1,299,979.31Zm-2.68,54.32a13.85,13.85,0,0,1-3.22-.37c-3.18-.68-5.15-3.36-5.76-7-1.32-8-8.47-10.87-8-12a3.18,3.18,0,0,1,3.18-1.89,7.91,7.91,0,0,1,4.24,1.39c1.62,1.11,2.51,2.47,4.06,2.47a4.28,4.28,0,0,0,2-.58c1.39-.71,2.07-1.89,3.12-5.35,1.22-4,4-7.15,7.62-7.25h.17c3.86,0,3.52,3.73,2.3,4.61a27.86,27.86,0,0,0-5.21,5.69,3.92,3.92,0,0,1,2.57,1.32c.07.1.14.17.2.27.78,1,.78,1.26.78,1.29a3.21,3.21,0,0,1,.95-.13,3.43,3.43,0,0,1,2.61,1.25C310.82,1020.52,307.13,1033.63,296.32,1033.63Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
