import { createStore } from "vuex";
import auth from "./auth";
import firestore from "./firestore";
import stats from "./stats";
import { markRaw } from "vue";

import { loadingController } from "@ionic/vue";

import Affection from "@/components/icons/Affection.vue";
import Agitation from "@/components/icons/Agitation.vue";
import Anger from "@/components/icons/Anger.vue";
import Anxiety from "@/components/icons/Anxiety.vue";
import Boredom from "@/components/icons/Boredom.vue";
import Calmness from "@/components/icons/Calmness.vue";
import Contentment from "@/components/icons/Contentment.vue";
import Delight from "@/components/icons/Delight.vue";
import Despair from "@/components/icons/Despair.vue";
import Disappointment from "@/components/icons/Disappointment.vue";
import Elation from "@/components/icons/Elation.vue";
import Embarrassment from "@/components/icons/Embarrassment.vue";
import Excitement from "@/components/icons/Excitement.vue";
import Friendliness from "@/components/icons/Friendliness.vue";
import Frustration from "@/components/icons/Frustration.vue";
import Guilt from "@/components/icons/Guilt.vue";
import Humility from "@/components/icons/Humility.vue";
import Interest from "@/components/icons/Interest.vue";
import Joy from "@/components/icons/Joy.vue";
import Pleasure from "@/components/icons/Pleasure.vue";
import Powerlessness from "@/components/icons/Powerlessness.vue";
import Pride from "@/components/icons/Pride.vue";
import Relaxation from "@/components/icons/Relaxation.vue";
import Sadness from "@/components/icons/Sadness.vue";
import Shock from "@/components/icons/Shock.vue";
import Stress from "@/components/icons/Stress.vue";
import Surprise from "@/components/icons/Surprise.vue";
import Worry from "@/components/icons/Worry.vue";

export default createStore({
  state() {
    return {
      loader: null,
      emotions: {
        negative: [
          {
            id: "angstig",
            text: "Angstig",
            icon: markRaw(Shock),
          },
          {
            id: "boos",
            text: "Boos",
            icon: markRaw(Anger),
          },
          {
            id: "schuldig",
            text: "Schuldig",
            icon: markRaw(Guilt),
          },
          {
            id: "teleurgesteld",
            text: "Teleurgesteld",
            icon: markRaw(Disappointment),
          },
          {
            id: "beschaamd",
            text: "Beschaamd",
            icon: markRaw(Embarrassment),
          },
          {
            id: "leeg",
            text: "Leeg",
            icon: markRaw(Boredom),
          },
          {
            id: "gefrustreerd",
            text: "Gefrustreerd",
            icon: markRaw(Frustration),
          },
          {
            id: "verdrietig",
            text: "Verdrietig",
            icon: markRaw(Sadness),
          },
          {
            id: "hopeloos",
            text: "Hopeloos",
            icon: markRaw(Agitation),
          },
          {
            id: "eenzaam",
            text: "Eenzaam",
            icon: markRaw(Powerlessness),
          },
          {
            id: "nerveus",
            text: "Nerveus",
            icon: markRaw(Anxiety),
          },
          {
            id: "overweldigd",
            text: "Overweldigd",
            icon: markRaw(Despair),
          },
          {
            id: "gestresst",
            text: "Gestresst",
            icon: markRaw(Stress),
          },
          {
            id: "bezorgd",
            text: "Bezorgd",
            icon: markRaw(Worry),
          },
        ],
        positive: [
          {
            id: "zelfverzekerd",
            text: "Zelfverzekerd",
            icon: markRaw(Relaxation),
          },
          {
            id: "opgewonden",
            text: "Opgewonden",
            icon: markRaw(Excitement),
          },
          {
            id: "voldaan",
            text: "Voldaan",
            icon: markRaw(Contentment),
          },
          {
            id: "dankbaar",
            text: "Dankbaar",
            icon: markRaw(Humility),
          },
          {
            id: "blij",
            text: "Blij",
            icon: markRaw(Pleasure),
          },
          {
            id: "geïnspireerd",
            text: "Geïnspireerd",
            icon: markRaw(Surprise),
          },
          {
            id: "geliefd",
            text: "Geliefd",
            icon: markRaw(Affection),
          },
          {
            id: "gemotiveerd",
            text: "Gemotiveerd",
            icon: markRaw(Interest),
          },
          {
            id: "optimistisch",
            text: "Optimistisch",
            icon: markRaw(Joy),
          },
          {
            id: "vredig",
            text: "Vredig",
            icon: markRaw(Calmness),
          },
          {
            id: "trots",
            text: "Trots",
            icon: markRaw(Pride),
          },
          {
            id: "relaxed",
            text: "Relaxed",
            icon: markRaw(Delight),
          },
          {
            id: "opgelucht",
            text: "Opgelucht",
            icon: markRaw(Elation),
          },
          {
            id: "tevreden",
            text: "Tevreden",
            icon: markRaw(Friendliness),
          },
        ],
      },
      distortions: [
        {
          id: "rampdenken",
          text: "Rampdenken",
          label: "Wat als het ergste scenario werkelijkheid wordt?",
          subLabel: "Catastroferen",
          explanation:
            "Voor toekomstige situaties schat je met zekerheid in dat ze negatief zullen aflopen. Alternatieve scenario’s/uitkomsten krijgen geen kans.",
          examples: [
            "als ik last krijg van duizeligheid, val ik flauw",
            "ik zet mezelf voor schut bij die presentatie",
            "ik word toch niet aangenomen",
          ],
          icon: "poo-storm",
        },
        {
          id: "zwart_wit_denken",
          text: "Zwart-wit denken",
          label: "Niemand mag mij",
          subLabel: "Dichotoom denken",
          explanation:
            "Het is een óf het ander, er is geen grijs gebied. Je gedachten bevatten woorden als altijd, iedereen, alles, niets of nooit. ",
          examples: [
            "niemand mag mij",
            "ik doe het nooit goed",
            "ik kan helemaal niks",
          ],
          icon: "yin-yang",
        },
        {
          id: "emotioneel_redeneren",
          text: "Emotioneel redeneren",
          label: "Ik voel me zo, dus het moet wel waar zijn",
          subLabel: "",
          explanation:
            "Je beschouwt je gevoelens als bewijs voor de juistheid van een gedachte.",
          examples: [
            "ik voel me een mislukking dus ben ik dat",
            "ik voel dat hij me niet mag dus is dat zo",
          ],
          icon: "heart",
        },
        {
          id: "uitvergroten_van_het_negatieve",
          text: "Focus op het negatieve",
          label: "Ik heb alles verpest",
          subLabel: "",
          explanation:
            "Negatieve interpretatie van gebeurtenissen, zelfs als dit neutraal of positief is",
          examples: [
            "het was puur geluk dat dit goed ging, ik kan het niet echt",
            "ze vragen me vast mee omdat ze het anders zielig vinden",
          ],
          icon: "minus-circle",
        },
        {
          id: "minimaliseren_van_het_positieve",
          text: "Minimaliseren van het positieve",
          label: "Ze meenden dat compliment niet echt",
          subLabel: "",
          explanation:
            "Je richt je aandacht met name op de negatieve details, waardoor de hele situatie of je jezelf negatief beoordeeld.",
          examples: [
            "ik heb deze week één keer in plaats van drie keer hardgelopen, de week is mislukt",
            "er zijn twee afzeggingen, mijn verjaardag is verpest",
            "alles ging fout",
          ],
          icon: "plus-circle",
        },
        {
          id: "overhaaste_conclusies_trekken",
          text: "Overhaaste conclusies trekken",
          label: "Er is een meeting ingepland, ik heb vast iets fout gedaan",
          subLabel: "",
          explanation: "",
          examples: [],
          icon: "running",
        },
        {
          id: "waarzeggerij",
          text: "Waarzeggerij",
          label: "Ik ga sowieso zakken voor mijn examen",
          subLabel: "Kans overschatten",
          explanation:
            "De kans op het optreden van een gevreesde gebeurtenis wordt overschat.",
          examples: [
            "hij gaat me zeker bijten (bij elke hond die je ziet)",
            "ik ga mezelf vast voor gek zetten bij dat feestje",
            "er is iets mis met mijn hersenen (bij hoofdpijn)",
          ],
          icon: "images",
        },
        {
          id: "gedachten_lezen",
          text: "Gedachten lezen",
          label: "Hij denkt vast dat ik een rare ben",
          subLabel: "",
          explanation:
            "Je vult de gedachten van anderen in (en betrekt deze mogelijk ook negatief op jezelf), mogelijke alternatieve verklaringen worden niet meegenomen.",
          examples: [
            "hij gaapt omdat hij me saai vind",
            "zij begroette mij niet op straat, dus ze mag me niet",
            "hij kwam te laat dus hij vindt me niet belangrijk",
          ],
          icon: "user-nurse",
        },
        {
          id: "zelfverwijten",
          text: "Personaliseren",
          label: "Die mensen op straat lachen om mij",
          subLabel: "Zelfverwijten",
          explanation:
            "Je betrekt gebeurtenissen of gedrag van een ander sterk op jezelf en trekt overhaaste conclusies.",
          examples: [
            "hij is natuurlijk chagrijnig door mij",
            "er is een extra vergadering ingepland, ik heb vast iets fout gedaan",
            "die mensen op straat lachen om mij",
          ],
          icon: "male",
        },
        {
          id: "met_twee_maten_meten",
          text: "Met twee maten meten",
          label: "Anderen mogen fouten maken, maar ik niet",
          subLabel: "",
          explanation:
            "Voor jou gelden strenge regels die niet voor anderen gelden.",
          examples: [
            "bij het maken van een fout, ‘ik ben een mislukking als ik een fout maak’, maar voor een ander ‘dat kan iedereen wel eens overkomen’",
          ],
          icon: "balance-scale-right",
        },
        {
          id: "overgeneraliseren",
          text: "Overgeneraliseren",
          label: "Alle mannen/vrouwen zijn niet te vertrouwen",
          subLabel: "",
          explanation:
            "Op grond van een of enkele negatieve ervaringen trek je overmatig negatieve conclusies",
          examples: [
            "ik voel me verdrietig, ik word weer depressief",
            "na mijn relatiebreuk weet ik het zeker, alle vrouwen/mannen zijn niet te vertrouwen",
            "ik heb een fout gemaakt op werk, dus ik kan het niet, ik ben te dom voor deze baan",
          ],
          icon: "expand-arrows-alt",
        },
        {
          id: "labelen",
          text: "Etiketten plakken",
          label: "Ik ben een mislukkeling",
          subLabel: "Etiketten plakken",
          explanation:
            "Je geeft snel een globaal, negatief oordeel over jezelf bij een tegenslag. Er is geen mildheid richting jezelf.",
          examples: [
            "wat ben ik ook een loser",
            "ik ben echt een sukkel",
            "ik ben een waardeloos figuur",
          ],
          icon: "tag",
        },
        {
          id: "moet_denken",
          text: "Moet-denken",
          label: "Ik moet hier aan voldoen, anders is het niet goed",
          subLabel: "",
          explanation:
            "Je gedachtes bevatten vaak het woordje ‘moet’ en hiermee zet je jezelf onder druk",
          examples: [
            "ik moet 60 kilo wegen anders ben ik onaantrekkelijk",
            "ik moet de was vandaag doen",
            "ik moet vandaag sporten",
          ],
          icon: "exclamation",
        },
      ],
    };
  },
  getters: {
    emotions: (state) => {
      return state.emotions;
    },
    distortions: (state) => {
      return state.distortions;
    },
  },
  mutations: {
    setLoader(state, loader) {
      state.loader = loader;
    },
  },
  actions: {
    showLoader({ commit }, loaderText) {
      return new Promise((resolve, reject) => {
        try {
          loadingController
            .create({
              message: loaderText,
              duration: 0,
            })
            .then((loader) => {
              commit("setLoader", loader);
              loader.present().then(() => {
                resolve(true);
              });
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    hideLoader({ state, commit }) {
      return new Promise((resolve, reject) => {
        try {
          if (!state.loader) {
            //location.reload();
            resolve(true);
            return;
          }
          state.loader
            .dismiss()
            .then(() => {
              commit("setLoader", null);
            })
            .catch((error) => {
              console.error(error);
              reject(error);
            });
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
  },
  modules: {
    auth,
    firestore,
    stats,
  },
});
