<template>
  <svg
    viewBox="1973.67 897.85 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2013.67,501.81a40,40,0,1,0,40,40A40,40,0,0,0,2013.67,501.81Zm-24.88,36.13,4.06-1.63a5.71,5.71,0,0,0,10.61,0l4.06,1.63a10.09,10.09,0,0,1-18.73,0Zm36.4,28.82a20.1,20.1,0,0,0-23.1,0l-4-5.62a26.93,26.93,0,0,1,31,0Zm4-22.48a10,10,0,0,1-9.37-6.34l4.06-1.63a5.72,5.72,0,0,0,10.62,0l4.06,1.63A10.11,10.11,0,0,1,2029.19,544.28Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
