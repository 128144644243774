<template>
  <svg
    viewBox="1404.44 879.1 88.012 99.43"
    width="88.012"
    height="99.43"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1454.8,507.33c-.82,0-1.63,0-2.45.08l-16.46-24.35-1.36,16.74-13-4.12-1.09,13.67-16-5.05,16.5,24.46a37.58,37.58,0,1,0,33.94-21.43ZM1423,524.92l-9.2-13.63,10.09,3.18,1.09-13.66,13,4.11.85-10.56,9.12,13.55A37.78,37.78,0,0,0,1423,524.92Zm14.33,10.79a7.34,7.34,0,1,1,14.67,0,3.66,3.66,0,0,1,0,.66h-14.59A3.44,3.44,0,0,1,1437.29,535.71Zm34,22a11.39,11.39,0,0,0-9.12-4.66,11.27,11.27,0,0,0-10.8,7.92l-5.16-1.63a16.72,16.72,0,0,1,29.47-4.85Zm9.32-21.39h-14.64c0-.23,0-.43,0-.66a7.34,7.34,0,1,1,14.68,0A3.63,3.63,0,0,1,1480.58,536.37Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
