<template>
  <svg
    version="1.1"
    width="1em"
    height="1em"
    x="0"
    y="0"
    viewBox="0 0 484.651 484.65"
    style="enable-background: new 0 0 512 512"
    class=""
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M 239.941 168.706 C 240.01 168.706 240.08 168.701 240.149 168.701 C 240.22 168.701 240.288 168.706 240.358 168.706 C 274.709 168.706 302.553 140.86 302.553 106.509 C 302.553 72.161 274.709 44.313 240.358 44.313 C 240.288 44.313 240.22 44.318 240.149 44.318 C 240.08 44.318 240.011 44.313 239.941 44.313 C 205.589 44.313 177.746 72.161 177.746 106.509 C 177.744 140.86 205.588 168.706 239.941 168.706 Z"
      style=""
    />
    <path
      fill="currentColor"
      d="M 396.154 295.926 L 326.017 244.928 L 303.838 190.767 C 299.617 180.461 289.565 174.316 279.054 174.548 L 279.051 174.524 L 239.025 174.524 L 199.001 174.524 L 198.997 174.548 C 188.486 174.316 178.434 180.461 174.213 190.767 L 152.033 244.928 L 81.896 295.926 C 70.213 304.422 67.626 320.78 76.123 332.465 C 81.242 339.501 89.217 343.24 97.301 343.24 C 102.635 343.24 108.017 341.612 112.661 338.234 L 188.495 283.101 L 186.562 303.062 L 114.86 375.736 C 104.912 386.086 102.107 401.378 107.734 414.587 C 113.36 427.795 126.329 436.369 140.686 436.369 L 174.959 436.369 C 166.127 428.376 160.496 416.447 160.496 403.127 C 160.496 379.111 178.754 359.574 201.198 359.574 C 201.198 359.574 227.45 359.574 240.236 359.574 C 253.023 359.574 263.169 370.028 263.169 370.028 L 201.198 370.028 C 184.141 370.028 170.264 384.875 170.264 403.127 C 170.264 421.378 184.141 436.226 201.198 436.226 L 261.215 436.226 C 261.888 436.178 262.574 436.175 263.237 436.096 L 335.786 436.096 C 350.143 436.096 363.111 427.521 368.738 414.311 C 374.365 401.105 371.56 385.814 361.613 375.462 L 291.473 302.697 L 291.453 302.697 L 289.557 283.101 L 365.389 338.234 C 370.031 341.613 375.416 343.24 380.747 343.24 C 388.833 343.24 396.808 339.501 401.926 332.465 C 410.424 320.78 407.838 304.422 396.154 295.926 Z"
      style=""
    />
  </svg>
</template>
