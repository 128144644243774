/* eslint-disable prettier/prettier */
import router from '../router'
import { auth, usersCollection } from '../firebase'
import axios from 'axios'

import { Buffer } from 'buffer'
window.Buffer = window.Buffer || Buffer

// TODO: map API responses, EN -> NL?
export default {
  namespaced: true,
  state() {
    return {
      user: {},
      userProfile: {},
      isLoggedIn: false,
      oobCode: null,
    }
  },
  getters: {
    isLoggedIn: (state) => {
      return state.isLoggedIn
    },
    user: (state) => {
      return state.user
    },
    userProfile: (state) => {
      return state.userProfile
    },
    userID: (state) => {
      return state.user.uid
    },
    userEmail() {
      return auth && auth.currentUser && auth.currentUser.email
    },
    isAdmin: (state) => {
      return 'isAdmin' in state.userProfile && state.userProfile.isAdmin
    },
    isEmailVerified() {
      return auth && auth.currentUser && auth.currentUser.emailVerified
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setIsLoggedIn(state, val) {
      state.isLoggedIn = val
    },
    setOobCode(state, code) {
      state.oobCode = code
    },
  },
  actions: {
    login({ commit, dispatch }, form) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .signInWithEmailAndPassword(form.email, form.password)
            .then(({ user }) => {
              if (user) {
                commit('setUser', user)
                if (user.emailVerified === false) {
                  dispatch('sendEmailVerification')
                }
                // dispatch("fetchUserProfile").then(() => {
                //   resolve(true);
                // });
                resolve(true)
              } else {
                reject('Gebruiksernaam of wachtwoord is niet juist')
              }
            })
            .catch((error) => {
              console.error('Error logging in:', error)
              if (error.code === 'auth/too-many-requests')
                reject(
                  'Sorry je hebt te vaak geprobeerd in te loggen. Probeer het over een paar minuten weer.'
                )
              else if (error.code === 'auth/wrong-password')
                reject('Gebruiksernaam of wachtwoord is niet juist')
              else reject('Gebruiksernaam of wachtwoord is niet juist')
            })
        } catch (err) {
          console.error(err)
          reject('Gebruiksernaam of wachtwoord is niet juist')
        }
      })
    },
    forgot(_, form) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .sendPasswordResetEmail(form.email)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              console.error('Error resetting password:', error)
              reject('We konden je wachtwoord niet resetten.')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je wachtwoord niet resetten.')
        }
      })
    },
    verifyEmail(_, oobCode) {
      return new Promise((resolve, reject) => {
        try {
          if (!oobCode) {
            console.error('oobCode is empty')
            reject(
              'We konden je verzoek niet valideren. Probeer opnieuw om je email adres te valideren'
            )
            return
          }

          auth
            .applyActionCode(oobCode)
            .then(() => {
              resolve(true)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        } catch (err) {
          console.error(err)
          reject('Het is ons niet gelukt om je email adres te valideren')
        }
      })
    },
    resetPassword({ state, commit }, password) {
      return new Promise((resolve, reject) => {
        try {
          if (!state.oobCode) {
            console.error('oobCode is empty')
            reject(
              'We konden je verzoek niet valideren. Probeer opnieuw je wachtwoord te resetten'
            )
            return
          }
          // Verify the password reset code is valid.
          auth
            .verifyPasswordResetCode(state.oobCode)
            .then((email) => {
              auth
                .confirmPasswordReset(state.oobCode, password)
                .then(() => {
                  auth
                    .signInWithEmailAndPassword(email, password)
                    .then(() => {
                      commit('setOobCode', null)
                      resolve(true)
                    })
                    .catch((error) => {
                      console.error(error)
                      reject(error)
                    })
                })
                .catch((error) => {
                  console.error(error)
                  reject(error)
                })
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        } catch (error) {
          console.error(error)
          reject(error)
        }
      })
    },
    sendEmailForgottenPassword(_, email) {
      return new Promise((resolve, reject) => {
        try {
          let payload = {
            email: Buffer.from(email).toString('base64'),
          }
          let url =
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080/api/sendForgottenPasswordCode'
              : 'https://gedachtegrip.web.app/api/sendForgottenPasswordCode'
          axios
            .post(url, payload)
            .then((response) => {
              console.log(response)
              resolve(true)
            })
            .catch((error) => {
              console.error('Error sending email verification')
              console.error(error)
              reject('We konden je email verificatie code niet versturen')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je email verificatie code niet versturen')
        }
      })
    },
    resetPasswordByCode(_, { email, inputCode, password }) {
      return new Promise((resolve, reject) => {
        try {
          let payload = {
            email: Buffer.from(email).toString('base64'),
            inputCode,
            password: Buffer.from(password).toString('base64'),
          }
          let url =
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080/api/resetPassword'
              : 'https://gedachtegrip.web.app/api/resetPassword'
          axios
            .post(url, payload)
            .then((response) => {
              console.log(response)
              resolve(true)
            })
            .catch((error) => {
              console.error('Error resetting password')
              console.error(error)
              reject('We konden je wachtwoord niet opnieuw instellen')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je wachtwoord niet opnieuw instellen')
        }
      })
    },
    sendEmailVerification({ state }) {
      return new Promise((resolve, reject) => {
        try {
          let payload = {
            userID: state.user.uid,
          }
          let url =
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080/api/sendVerifyCode'
              : 'https://gedachtegrip.web.app/api/sendVerifyCode'
          axios
            .post(url, payload)
            .then((response) => {
              console.log(response)
              resolve(true)
            })
            .catch((error) => {
              console.error('Error sending email verification')
              console.error(error)
              reject('We konden je email verificatie code niet versturen')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je email verificatie code niet versturen')
        }
      })
    },
    verifyEmailByCode({ state }, inputCode) {
      return new Promise((resolve, reject) => {
        try {
          let payload = {
            userID: state.user.uid,
            inputCode,
          }
          let url =
            process.env.NODE_ENV === 'development'
              ? 'http://localhost:8080/api/verifyEmailByCode'
              : 'https://gedachtegrip.web.app/api/verifyEmailByCode'
          axios
            .post(url, payload)
            .then((response) => {
              console.log(response)
              resolve(true)
            })
            .catch((error) => {
              console.error('Error sending email verification')
              console.error(error)
              reject('We konden je email verificatie code niet controleren')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je email verificatie code niet controleren')
        }
      })
    },
    register({ dispatch, commit }, form) {
      return new Promise((resolve, reject) => {
        try {
          auth
            .createUserWithEmailAndPassword(form.email, form.password)
            .then(({ user }) => {
              if (user) {
                usersCollection
                  .doc(user.uid)
                  .set({
                    profile: {
                      firstname: form.firstname,
                      lastname: form.lastname,
                    },
                    createdAt: new Date(),
                  })
                  .then(() => {
                    commit('setUser', user)
                    user
                      .updateProfile({
                        displayName: `${form.firstname}`,
                      })
                      .then(() => {
                        dispatch('sendEmailVerification').then(() => {
                          resolve(true)
                        })
                      })
                      .catch((error) => {
                        console.error('Error getting user profile:', error)
                        reject('We konden je account niet aanmaken')
                      })
                  })
                  .catch((error) => {
                    console.error('Error getting user profile:', error)
                    reject('We konden je account niet aanmaken')
                  })
              } else {
                reject('We konden je account niet aanmaken')
              }
            })
            .catch((error) => {
              console.error('Error creating user:', error)
              if (error.code === 'auth/email-already-in-use')
                reject('Er is al een account aangemaakt met dit email adres')
              else reject('We konden je account niet aanmaken')
            })
        } catch (err) {
          console.error(err)
          reject('We konden je account niet aanmaken')
        }
      })
    },
    logout({ commit }, redirect = true) {
      auth.signOut().then(() => {
        commit('setUser', {})
        commit('setUserProfile', {})
        if (redirect)
          router.replace({ name: 'login', params: { logout: true } })
      })
    },
    /*
    getGedachten({ commit }, userID) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(userID)
            .collection("gedachten")
            .orderBy("isoDate", "desc")
            .onSnapshot((querySnapshot) => {
              let gedachten = [];
              querySnapshot.forEach((doc) => {
                let gedachteData = doc.data();
                gedachteData.id = doc.id;
                gedachten.push(gedachteData);
              });
              commit("setGedachten", gedachten);
              resolve(true);
            });
        } catch (error) {
          console.error("Error getting gedachten: ", error);
          reject(error);
        }
      });
    },
    */
    getUserProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          usersCollection.doc(state.user.uid).onSnapshot(
            (userProfile) => {
              commit('setUserProfile', userProfile.data())
              resolve(true)
            },
            (error) => {
              console.error(error)
            }
          )
        } catch (error) {
          console.error('Error getting user profile: ', error)
          reject(error)
        }
      })
    },
    updateProfile({ state }, form) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              profile: form,
            },
            { merge: true }
          )
          .then(() => {
            // dispatch("fetchUserProfile").then(() => {
            //   resolve(true);
            // });
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setOnboardingDone({ state }, subject) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              onBoarding: {
                [subject]: true,
              },
            },
            { merge: true }
          )
          .then(() => {
            // dispatch("fetchUserProfile").then(() => {
            //   resolve(true);
            // });
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setVerifyEmailSend({ state }) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              stats: {
                verifyEmailSend: new Date(),
              },
            },
            { merge: true }
          )
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setLastLogin({ state }) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              stats: {
                lastLogin: new Date(),
              },
            },
            { merge: true }
          )
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    showFeedbackCard({ state }, showFeedbackCard) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              profile: {
                showFeedbackCard: showFeedbackCard,
              },
            },
            { merge: true }
          )
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setAppVersion({ state }, version) {
      console.log('setAppVersion')
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              stats: {
                appVersion: version,
              },
            },
            { merge: true }
          )
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setAppPlatform({ state }, platform) {
      console.log('setAppPlatform')
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              stats: {
                platform: platform,
              },
            },
            { merge: true }
          )
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setAppPayScreenSeen({ state }) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              stats: {
                lastAppPayScreenSeen: new Date(),
              },
            },
            { merge: true }
          )
          .then(() => {
            // dispatch("fetchUserProfile").then(() => {
            //   resolve(true);
            // });
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setAppPaid({ state }, transaction) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              paid: {
                transaction: transaction,
                date: new Date(),
              },
            },
            { merge: true }
          )
          .then(() => {
            // dispatch("fetchUserProfile").then(() => {
            //   resolve(true);
            // });
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    deleteAppPaid({ state }) {
      return new Promise((resolve, reject) => {
        usersCollection
          .doc(state.user.uid)
          .set(
            {
              paid: null,
            },
            { merge: true }
          )
          .then(() => {
            // dispatch("fetchUserProfile").then(() => {
            //   resolve(true);
            // });
            resolve(true)
          })
          .catch((error) => {
            console.error('Error updating profile: ', error)
            reject(error)
          })
      })
    },
    setIsLoggedIn({ commit }, isLoggedIn) {
      commit('setIsLoggedIn', isLoggedIn)
    },
    setUser({ commit }, user) {
      return new Promise((resolve) => {
        commit('setUser', user)
        resolve(true)
      })
    },
  },
}
