<template>
  <svg
    viewBox="524.06 1326.35 90 95.32"
    width="90"
    height="95.32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M574.33,948.41a30.58,30.58,0,0,0-28.13,54.09c-4.8-.19-6.4-.08-7.11.3a2.06,2.06,0,0,0-1.27,2.05c.19,1.9,2.61,2.68,9.61,4.65,2.09.6,5.18,1.45,7.12,2.2-2.13.3-5.52.45-7.79.56s-4.47.22-6.14.41-4.06.45-4.06,2.42c0,3.54,5.58,4.1,11.54,4.69a25.85,25.85,0,0,1,4.85.71,3.15,3.15,0,0,1,.37.19c-1.23.74-5,1.82-11.88,1.82v3.13c3.39,0,14.45-.33,15.31-4.51.18-.82,0-2.42-2.61-3.5a24.9,24.9,0,0,0-5.78-.93,58.19,58.19,0,0,1-6.78-1c1.57-.15,3.62-.22,5.29-.3,8.31-.37,12.3-.67,12.3-3.42,0-2.24-2.83-3.24-10.92-5.52-.89-.26-2-.55-3-.85,2.76.07,6.11.26,8.57.41a1.89,1.89,0,0,1,.41,0,30.6,30.6,0,0,0,20.08-57.66Zm-20.27,13.52a9.5,9.5,0,0,1,5.74,6.3l-3,.78a6.25,6.25,0,0,0-3.87-4.17,5.72,5.72,0,0,0-5.14.3l-1.64-2.65A8.71,8.71,0,0,1,554.06,961.93Zm-.26,34.91a16.29,16.29,0,0,1-8.75-21.12l30.06,12.74A16.39,16.39,0,0,1,553.8,996.84Zm26.78-17.73a6.24,6.24,0,0,0-3.87-4.18,5.79,5.79,0,0,0-5.14.3l-1.64-2.64a8.7,8.7,0,0,1,7.93-.56,9.46,9.46,0,0,1,5.74,6.29Zm26.27,1.56-10.17-.89.33-3.91,10.17.89Zm-32.26-36.8-3.77-1.16,3.81-12.4,3.76,1.15Zm22,23.73-1.19-3.77,17.47-5.58,1.19,3.76Zm-43.92-23.7-2.38-7.33,3.72-1.23,2.39,7.33Zm-17.43,12.3-11.18-8.5,2.31-3.05,11.18,8.49Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
