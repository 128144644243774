<template>
  <svg
    viewBox="2374.9 1116.545 104.33 85.805"
    width="104.33"
    height="85.805"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2479.23,749.63l-11.65-7.91,5.7-16.22-11.37,10.93L2448.3,725l5.54,14.34a8.32,8.32,0,0,1-3.54-1.47c-3.55-2.48-4.32-5.17-5.13-8.07-.66-2.28-1.35-4.64-3.43-6.68-2.85-2.81-6.44-3.38-9.53-1.59a7.87,7.87,0,0,0-3.91,5.54l-6.69-3-3.18,7.05a36.85,36.85,0,0,0-5.7-.45,37.82,37.82,0,1,0,37.81,37.81,37.39,37.39,0,0,0-5.05-18.86l8.76-1.76-2.44,11.29,9.29-5.78,12.14,13.28-5.42-18.26Zm-88.3,12.83a7,7,0,1,1,7,7A7,7,0,0,1,2390.93,762.46ZM2429,786.79l-5.79-4.56-7,5.5-7-5.5-7.05,5.5-8.15-6.44,2.36-3,5.79,4.57,7-5.5,7,5.5,7-5.5,8.19,6.43Zm-1.51-17.36a7,7,0,1,1,7-7A7,7,0,0,1,2427.48,769.43Zm7.78-31.29,3-6.6-7.38-3.35a5.21,5.21,0,0,1,2.69-4.4c2-1.18,4.36-.77,6.32,1.19,1.59,1.54,2.08,3.38,2.69,5.5.85,3,1.87,6.52,6.19,9.53a11.79,11.79,0,0,0,3.47,1.67l-8.64,5A36.92,36.92,0,0,0,2435.26,738.14Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
