import { db, firestore } from "../firebase";

export default {
  namespaced: true,
  state() {
    return {
      gedachten: [],
      lastGedachten: [],
      dankbaarheden: [],
      lastDankbaarheden: [],
      tips: [],
      currentFeedbackID: null,
      feedback: [],
    };
  },
  getters: {
    gedachten: (state) => {
      return state.gedachten;
    },
    lastGedachten: (state) => {
      return state.lastGedachten;
    },
    dankbaarheden: (state) => {
      return state.dankbaarheden;
    },
    lastDankbaarheden: (state) => {
      return state.lastDankbaarheden;
    },
    tips: (state) => {
      return state.tips;
    },
    currentFeedbackID: (state) => {
      return state.currentFeedbackID;
    },
    feedback: (state) => {
      return state.feedback;
    },
  },
  mutations: {
    setGedachten(state, gedachten) {
      state.gedachten = gedachten;
    },
    setLastGedachten(state, gedachten) {
      state.lastGedachten = gedachten;
    },
    setDankbaarheden(state, dankbaarheden) {
      state.dankbaarheden = dankbaarheden;
    },
    setLastDankbaarheden(state, dankbaarheden) {
      state.lastDankbaarheden = dankbaarheden;
    },
    setTips(state, tips) {
      state.tips = tips;
    },
    setCurrentFeedbackID(state, docID) {
      state.currentFeedbackID = docID;
    },
    setFeedback(state, feedback) {
      state.feedback = feedback;
    },
  },
  actions: {
    addFeedback({ state, rootGetters, commit }, payload) {
      return new Promise((resolve, reject) => {
        const userID = rootGetters["auth/userID"];
        const feedbackSatisfied =
          "satisfied" in payload ? payload["satisfied"] : null;
        const feedbackDescription =
          "description" in payload ? payload["description"] : null;
        const currentFeedbackID = state.currentFeedbackID
          ? state.currentFeedbackID
          : null;
        console.log(feedbackSatisfied);
        console.log(feedbackDescription);
        console.log(currentFeedbackID);
        try {
          if (currentFeedbackID) {
            db.collection("feedback")
              .doc(currentFeedbackID)
              .set(
                {
                  satisfied: feedbackSatisfied,
                  description: feedbackDescription,
                },
                { merge: true }
              )
              .then(() => {
                commit("setCurrentFeedbackID", null);
                resolve(true);
              })
              .catch((error) => {
                console.error("Error creating feedback: ", error);
                reject(error);
              });
          } else {
            db.collection("feedback")
              .add(
                {
                  userID,
                  satisfied: feedbackSatisfied,
                  description: feedbackDescription,
                  created_at: firestore.FieldValue.serverTimestamp(),
                },
                { merge: true }
              )
              .then((docRef) => {
                commit("setCurrentFeedbackID", docRef.id);
                resolve(true);
              })
              .catch((error) => {
                console.error("Error creating feedback: ", error);
                reject(error);
              });
          }
        } catch (error) {
          console.error("Error creating feedback: ", error);
          reject(error);
        }
      });
    },
    getFeedback({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("feedback")
            .orderBy("created_at", "desc")
            .onSnapshot(
              (querySnapshot) => {
                let feedback = [];
                querySnapshot.forEach((doc) => {
                  let feedbackData = doc.data();
                  feedbackData.id = doc.id;
                  feedback.push(feedbackData);
                });
                commit("setFeedback", feedback);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting feedback: ", error);
          reject(error);
        }
      });
    },
    updateFeedbackIsRead(_, feedbackID) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("feedback")
            .doc(feedbackID)
            .set({ isRead: true }, { merge: true })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error updating feedback: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error updating feedback: ", error);
          reject(error);
        }
      });
    },
    addGedachte(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          payload.gedachte.created_at = firestore.FieldValue.serverTimestamp();
          payload.gedachte.updated_at = firestore.FieldValue.serverTimestamp();
          db.collection("users")
            .doc(payload.userID)
            .collection("gedachten")
            .add(payload.gedachte)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error creating gedachte: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error creating gedachte: ", error);
          reject(error);
        }
      });
    },
    updateGedachte(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          let gedachteID = payload.gedachte.id;
          delete payload.gedachte.id;
          payload.gedachte.updated_at = firestore.FieldValue.serverTimestamp();
          db.collection("users")
            .doc(payload.userID)
            .collection("gedachten")
            .doc(gedachteID)
            .update(payload.gedachte)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error updating gedachte: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error updating gedachte: ", error);
          reject(error);
        }
      });
    },
    deleteGedachte(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(payload.userID)
            .collection("gedachten")
            .doc(payload.gedachteID)
            .delete()
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error deleting gedachte: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error deleting gedachte: ", error);
          reject(error);
        }
      });
    },
    getGedachten({ commit }, userID) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(userID)
            .collection("gedachten")
            .orderBy("isoDate", "desc")
            .onSnapshot(
              (querySnapshot) => {
                let gedachten = [];
                querySnapshot.forEach((doc) => {
                  let gedachteData = doc.data();
                  gedachteData.id = doc.id;
                  gedachten.push(gedachteData);
                });
                commit("setGedachten", gedachten);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting gedachten: ", error);
          reject(error);
        }
      });
    },
    getLastGedachten({ commit }, payload) {
      const userID = payload.userID;
      const amount = payload.amount;
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(userID)
            .collection("gedachten")
            .orderBy("isoDate", "desc")
            .limit(amount)
            .onSnapshot(
              (querySnapshot) => {
                let gedachten = [];
                querySnapshot.forEach((doc) => {
                  let gedachteData = doc.data();
                  gedachteData.id = doc.id;
                  gedachten.push(gedachteData);
                });
                commit("setLastGedachten", gedachten);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting gedachten: ", error);
          reject(error);
        }
      });
    },

    addDankbaarheid(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          payload.dankbaarheid.created_at =
            firestore.FieldValue.serverTimestamp();
          payload.dankbaarheid.updated_at =
            firestore.FieldValue.serverTimestamp();
          db.collection("users")
            .doc(payload.userID)
            .collection("dankbaarheden")
            .add(payload.dankbaarheid)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error creating dankbaarheid: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error creating dankbaarheid: ", error);
          reject(error);
        }
      });
    },
    updateDankbaarheid(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          let dankbaarheidID = payload.dankbaarheid.id;
          delete payload.dankbaarheid.id;
          payload.dankbaarheid.updated_at =
            firestore.FieldValue.serverTimestamp();
          db.collection("users")
            .doc(payload.userID)
            .collection("dankbaarheden")
            .doc(dankbaarheidID)
            .update(payload.dankbaarheid)
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error updating dankbaarheid: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error updating dankbaarheid: ", error);
          reject(error);
        }
      });
    },
    deleteDankbaarheid(_, payload) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(payload.userID)
            .collection("dankbaarheden")
            .doc(payload.dankbaarheidID)
            .delete()
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              console.error("Error deleting dankbaarheid: ", error);
              reject(error);
            });
        } catch (error) {
          console.error("Error deleting dankbaarheid: ", error);
          reject(error);
        }
      });
    },
    getDankbaarheden({ commit }, userID) {
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(userID)
            .collection("dankbaarheden")
            .orderBy("isoDate", "desc")
            .onSnapshot(
              (querySnapshot) => {
                let dankbaarheden = [];
                querySnapshot.forEach((doc) => {
                  let dankbaarheidsData = doc.data();
                  dankbaarheidsData.id = doc.id;
                  dankbaarheden.push(dankbaarheidsData);
                });
                commit("setDankbaarheden", dankbaarheden);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting dankbaarheden: ", error);
          reject(error);
        }
      });
    },
    getLastDankbaarheden({ commit }, payload) {
      const userID = payload.userID;
      const amount = payload.amount;
      return new Promise((resolve, reject) => {
        try {
          db.collection("users")
            .doc(userID)
            .collection("dankbaarheden")
            .orderBy("isoDate", "desc")
            .limit(amount)
            .onSnapshot(
              (querySnapshot) => {
                let dankbaarheden = [];
                querySnapshot.forEach((doc) => {
                  let dankbaarheidsData = doc.data();
                  dankbaarheidsData.id = doc.id;
                  dankbaarheden.push(dankbaarheidsData);
                });
                commit("setLastDankbaarheden", dankbaarheden);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting dankbaarheden: ", error);
          reject(error);
        }
      });
    },
    getTips({ commit, getters }) {
      return new Promise((resolve, reject) => {
        try {
          let tips = getters.tips;
          db.collection("tips")
            .orderBy("created_at", "desc")
            .onSnapshot(
              (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                  let data = change.doc.data();
                  data.id = change.doc.id;
                  if (change.type === "added") {
                    tips.push(data);
                  }
                  // if (change.type === "modified") {
                  //     console.log("Modified: ", change.doc.data());
                  // }
                  if (change.type === "removed") {
                    let index = tips.indexOf(data.id);
                    if (index > -1) {
                      tips.splice(index, 1);
                    }
                  }
                });
                commit("setTips", tips);
                resolve(true);
              },
              (error) => {
                console.error(error);
              }
            );
        } catch (error) {
          console.error("Error getting tips: ", error);
          reject(error);
        }
      });
    },
  },
};
