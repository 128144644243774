<template>
  <svg
    viewBox="1680.66 1118.04 87.54 90.66"
    width="87.54"
    height="90.66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1722.48,736a38.33,38.33,0,1,0,38.32,38.35A38.35,38.35,0,0,0,1722.48,736Zm-24.82,33h10.23l-5.21-4.88,2.48-2.62,8.5,8-1.24,3.12h-14.76Zm40.72,27.13c-1.31,6.62-8.07,2.13-10.59.39-7-4.89-10,1.84-13.49,3.47s-8.15-1.1-7.86-6.41a9.46,9.46,0,0,1,12.36-8.46c4.56,1.56,6.58,4.07,10.41,4.57C1732.36,790.11,1739.44,790.85,1738.38,796.13Zm8.89-23.55H1732.5l-1.24-3.12,8.5-8,2.48,2.62L1737,769h10.23Zm3.65-29.64-3.86-3.43,12.36-14,3.86,3.44Zm6.58,7.3-3-4.22,10.7-7.61,3,4.21ZM1695.92,740l-8.25-13.67,4.43-2.69,8.25,13.63Zm47.77-18a3.83,3.83,0,1,1-3.82,3.82A3.83,3.83,0,0,1,1743.69,722.05Zm-58.54,14.73a4.5,4.5,0,1,1-4.49,4.5A4.51,4.51,0,0,1,1685.15,736.78Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
