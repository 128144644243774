<template>
  <svg
    viewBox="683.03 643.15 80.87 110.53"
    width="80.87"
    height="110.53"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M721.36,281a38.32,38.32,0,1,0,38.31,38.31A38.32,38.32,0,0,0,721.36,281Zm-21.69,29a9.8,9.8,0,0,1,3.37-7.76,4.37,4.37,0,1,0,7.91,2.58,4.31,4.31,0,0,0-.84-2.58,9.94,9.94,0,0,1,3.38,7.76c0,5-3.07,9.06-6.87,9.06S699.67,315,699.67,310ZM721.36,346a16.72,16.72,0,0,1-16.74-16.47h33.51A16.8,16.8,0,0,1,721.36,346Zm14.82-27c-3.8,0-6.88-4-6.88-9.06a9.81,9.81,0,0,1,3.38-7.76,4.38,4.38,0,1,0,7.07,0,9.94,9.94,0,0,1,3.38,7.76C743.05,315,740,319.05,736.18,319.05Zm-38.37-41.48-12.48-16.14,3.38-2.61L701.19,275Zm27.58-12.21v-18.2h4.29v18.2Zm16.88,1.27,6.59-16.32,4,1.62-6.59,16.32Zm12.42,7.79,5.86-7.41,3.35,2.66L758,277.08Zm-45-5.94-3.54-8.29,4-1.69,3.54,8.29Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
