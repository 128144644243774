/* eslint-disable prettier/prettier */
import { db } from "../firebase";
import { sub, differenceInCalendarDays } from 'date-fns'

export default {
    namespaced: true,
    state() {
        return {
            gedachten: [],
            dankbaarheden: [],
            users: [],
            totalUsersRecentlyActive: 0,
            usersStats: []
        };
    },
    getters: {
        gedachten: (state) => {
            return state.gedachten;
        },
        dankbaarheden: (state) => {
            return state.dankbaarheden;
        },
        users: (state) => {
            return state.users;
        },
        totalUsersRecentlyActive: (state) => {
            return state.totalUsersRecentlyActive;
        },
        usersStats: (state) => {
            return state.usersStats
        }
    },
    mutations: {
        setGedachten(state, gedachten) {
            state.gedachten = gedachten;
        },
        setDankbaarheden(state, dankbaarheden) {
            state.dankbaarheden = dankbaarheden;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setTotalUsersRecentlyActive(state, total) {
            state.totalUsersRecentlyActive = total;
        },
        setUsersStats(state, stats) {
            state.usersStats = stats;
        },
    },
    actions: {
        getUsersStats({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    db.collection("users_statistics")
                        .onSnapshot(
                            (querySnapshot) => {
                                let rawUsersStats = [];
                                querySnapshot.forEach((doc) => {
                                    let userStats = doc.data();
                                    userStats.id = doc.id;
                                    rawUsersStats.push(userStats);
                                });

                                let lastUpdate = {};
                                let totalDankbaarhedenPerUser = {};
                                let totalGedachtenPerUser = {};
                                let totalInactiveUsersGedachten = 0;
                                let totalInactiveUsersDankbaarheden = 0;
                                rawUsersStats.forEach((stat) => {
                                    let differenceInCalendarDaysGedachten = -1;
                                    let differenceInCalendarDaysDankbaarheden = -1;
                                    if (stat.gedachten.lastUpdate) {
                                        differenceInCalendarDaysGedachten = differenceInCalendarDays(new Date(), new Date(stat.gedachten.lastUpdate.seconds * 1000));
                                    }
                                    if (stat.dankbaarheden.lastUpdate) {
                                        differenceInCalendarDaysDankbaarheden = differenceInCalendarDays(new Date(), new Date(stat.dankbaarheden.lastUpdate.seconds * 1000));
                                    }

                                    let difference = Math.max(differenceInCalendarDaysGedachten, differenceInCalendarDaysDankbaarheden);
                                    if (difference > -1) {
                                        if (difference <= 7) lastUpdate["0-7"] = lastUpdate["0-7"] ? lastUpdate["0-7"] + 1 : 1;
                                        else if (difference > 7 && difference <= 14) lastUpdate["8-14"] = lastUpdate["8-14"] ? lastUpdate["8-14"] + 1 : 1;
                                        else if (difference > 14 && difference <= 21) lastUpdate["14-21"] = lastUpdate["14-21"] ? lastUpdate["14-21"] + 1 : 1;
                                        else if (difference > 21 && difference <= 28) lastUpdate["21-18"] = lastUpdate["21-18"] ? lastUpdate["21-18"] + 1 : 1;
                                        else lastUpdate[">28"] = lastUpdate[">28"] ? lastUpdate[">28"] + 1 : 1;
                                    }

                                    if (stat.gedachten.total === 0) {
                                        totalInactiveUsersGedachten++;
                                    }

                                    if (stat.gedachten.total === 1) {
                                        totalGedachtenPerUser["1"] = totalGedachtenPerUser["1"] ? totalGedachtenPerUser["1"] + 1 : 1;
                                    }
                                    else if (stat.gedachten.total > 1 && stat.gedachten.total <= 3) {
                                        totalGedachtenPerUser["2-3"] = totalGedachtenPerUser["2-3"] ? totalGedachtenPerUser["2-3"] + 1 : 1;
                                    }
                                    else if (stat.gedachten.total > 3 && stat.gedachten.total <= 5) {
                                        totalGedachtenPerUser["4-5"] = totalGedachtenPerUser["4-5"] ? totalGedachtenPerUser["4-5"] + 1 : 1;
                                    }
                                    else if (stat.gedachten.total > 5) {
                                        totalGedachtenPerUser[">5"] = totalGedachtenPerUser[">5"] ? totalGedachtenPerUser[">5"] + 1 : 1;
                                    }

                                    if (stat.dankbaarheden.total === 0) {
                                        totalInactiveUsersDankbaarheden++;
                                    }

                                    if (stat.dankbaarheden.total === 1) {
                                        totalDankbaarhedenPerUser["1"] = totalDankbaarhedenPerUser["1"] ? totalDankbaarhedenPerUser["1"] + 1 : 1;
                                    }
                                    else if (stat.dankbaarheden.total > 1 && stat.dankbaarheden.total <= 3) {
                                        totalDankbaarhedenPerUser["2-3"] = totalDankbaarhedenPerUser["2-3"] ? totalDankbaarhedenPerUser["2-3"] + 1 : 1;
                                    }
                                    else if (stat.dankbaarheden.total > 3 && stat.dankbaarheden.total <= 5) {
                                        totalDankbaarhedenPerUser["4-5"] = totalDankbaarhedenPerUser["4-5"] ? totalDankbaarhedenPerUser["4-5"] + 1 : 1;
                                    }
                                    else if (stat.dankbaarheden.total > 5) {
                                        totalDankbaarhedenPerUser[">5"] = totalDankbaarhedenPerUser[">5"] ? totalDankbaarhedenPerUser[">5"] + 1 : 1;
                                    }

                                    // lastUpdateGedachten[differenceInCalendarDaysGedachten] = lastUpdateGedachten[differenceInCalendarDaysGedachten] ? lastUpdateGedachten[differenceInCalendarDaysGedachten] + 1 : 1;
                                })

                                commit("setUsersStats", { lastUpdate, totalGedachtenPerUser, totalDankbaarhedenPerUser, totalInactiveUsersGedachten, totalInactiveUsersDankbaarheden });
                                resolve(true);
                            },
                            (error) => {
                                console.error(error);
                            }
                        );
                } catch (error) {
                    console.error("Error getting gedachten: ", error);
                    reject(error);
                }
            });
        },
        getGedachten({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    db.collection("statistics")
                        .doc("gedachten")
                        .onSnapshot(
                            (doc) => {
                                commit("setGedachten", doc.data());
                                resolve(true);
                            },
                            (error) => {
                                console.error(error);
                                reject(error);
                            }
                        );
                } catch (error) {
                    console.error("Error getting gedachten: ", error);
                    reject(error);
                }
            });
        },
        getDankbaarheden({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    db.collection("statistics")
                        .doc("dankbaarheden")
                        .onSnapshot(
                            (doc) => {
                                commit("setDankbaarheden", doc.data());
                                resolve(true);
                            },
                            (error) => {
                                console.error(error);
                                reject(error);
                            }
                        );
                } catch (error) {
                    console.error("Error getting dankbaarheden: ", error);
                    reject(error);
                }
            });
        },
        getUsers({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    db.collection("statistics")
                        .doc("users")
                        .onSnapshot(
                            (doc) => {
                                commit("setUsers", doc.data());
                                resolve(true);
                            },
                            (error) => {
                                console.error(error);
                                reject(error);
                            }
                        );
                } catch (error) {
                    console.error("Error getting ysers: ", error);
                    reject(error);
                }
            });
        },
        getTotalUsersRecentlyActive({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    db.collection("users")
                        .where("stats.lastLogin", ">=", sub(new Date(), { weeks: 2 }))
                        .onSnapshot(
                            (querySnapshot) => {
                                let users = [];
                                querySnapshot.forEach((doc) => {
                                    let userData = doc.data();
                                    userData.id = doc.id;
                                    users.push(userData);
                                });
                                commit("setTotalUsersRecentlyActive", users.length);
                                resolve(true);
                            },
                            (error) => {
                                console.error(error);
                            }
                        );
                } catch (error) {
                    console.error("Error getting ysers: ", error);
                    reject(error);
                }
            });
        },
    },
};
