<template>
  <svg
    viewBox="961.67 1092.4 73.48 92.48"
    width="73.48"
    height="92.48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M998.42,713.41c-10.76,0-22.32-2.67-22.32-8.53s11.56-8.52,22.32-8.52,22.32,2.67,22.32,8.52S1009.19,713.41,998.42,713.41Zm0-12.93c-11.95,0-18.2,3.25-18.2,4.4s6.25,4.41,18.2,4.41,18.21-3.25,18.21-4.41S1010.38,700.48,998.42,700.48Zm0,14.88a36.74,36.74,0,1,0,36.73,36.73A36.71,36.71,0,0,0,998.42,715.36Zm-15.13,38.47a9.69,9.69,0,0,1-7.84-4l2.82-2a6.33,6.33,0,0,0,5.09,2.52,7.07,7.07,0,0,0,5.74-2.56l2.71,2.1a10.46,10.46,0,0,1-8.27,3.9Zm15.1,19.79a20.54,20.54,0,0,1-8.38-1.77l-.62-.25,2.06-4.74.61.26a15.86,15.86,0,0,0,13.19-.22l2.24,4.66A21.25,21.25,0,0,1,998.39,773.62Zm14.73-19.79h-.25a9.71,9.71,0,0,1-7.84-4l2.82-2a6.33,6.33,0,0,0,5.09,2.52,7.07,7.07,0,0,0,5.74-2.56l2.71,2.1A10.55,10.55,0,0,1,1013.12,753.83Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
