<template>
  <svg
    viewBox="1825 1128.45 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1865,732.41a40,40,0,1,0,40,40A40,40,0,0,0,1865,732.41Zm-17.52,45.8c-3.5-1.12-5.37-5.09-4.68-9.27a20.25,20.25,0,0,1,6-11.08,15.36,15.36,0,0,1,11-4l-.22,4.37a11,11,0,0,0-7.74,2.84,6.78,6.78,0,0,1,1.06.25c3.9,1.25,5.84,6,4.34,10.71S1851.34,779.43,1847.44,778.21ZM1878,798.7c-1.57,1.25-5.62-1.53-13-1.53-6.62,0-11.43,2.81-13,1.53-.75-.59-.85-1.38-.16-3a15.28,15.28,0,0,1,26.29,0C1878.79,797.32,1878.73,798.1,1878,798.7Zm4.52-20.49c-3.9,1.25-8.24-1.53-9.74-6.21s.44-9.49,4.34-10.71a6.07,6.07,0,0,1,1.06-.25,11,11,0,0,0-7.74-2.84l-.22-4.37a15.44,15.44,0,0,1,11,4,20.12,20.12,0,0,1,6,11.08C1887.81,773.13,1885.94,777.09,1882.47,778.21Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
