<template>
  <ion-app>
    <ion-router-outlet id="ion-router-outlet-content" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/keyboard";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/zoom";
import "@ionic/vue/css/ionic-swiper.css";

export default defineComponent({
  name: "App",
  computed: {
    ...mapGetters({
      userProfile: "auth/userProfile",
    }),
  },
  data() {
    return {};
  },
  components: {
    IonApp,
    IonRouterOutlet,
  },
  watch: {
    userProfile: {
      handler() {
        if (
          this.userProfile &&
          this.userProfile.profile &&
          "darkMode" in this.userProfile.profile &&
          this.userProfile.profile.darkMode
        ) {
          document.body.classList.add("dark");
        } else {
          document.body.classList.remove("dark");
        }
      },
      immediate: true,
    },
  },
});
</script>

<style>
/* :root {
  --ion-font-family: "Quicksand", sans-serif;
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("./assets/fonts/Quicksand-Regular.ttf") format("truetype");
} */

/*.swiper.modalAddGedachte .swiper-wrapper {
  min-height: calc(100vh - 90px) !important;
}*/

.d-inline-block {
  display: inline-block !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

ion-title {
  letter-spacing: 0;
}

.position-absolute {
  position: absolute;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.d-block {
  display: block;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-row {
  flex-direction: row;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.colorBlack {
  color: black;
}

body.dark .colorBlack {
  color: var(--ion-color-step-500);
}

.colorPrimary {
  color: var(--ion-color-primary);
}

.d-visibility-hidden {
  visibility: hidden;
}

.d-none {
  display: none !important;
}

.d-block-inline {
  display: inline-block !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.white-space-normal {
  white-space: normal !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

ion-img#logo {
  width: 50px;
}

a {
  text-decoration: none !important;
}

.bold {
  font-weight: bold !important;
}

ion-item.error {
  --border-color: var(--ion-color-primary) !important;
}

ion-content:not(.bg-none),
ion-toolbar:not(.bg-none) {
  --background: #f2f2f7 !important;
}

body.dark ion-content,
body.dark ion-toolbar {
  --background: var(--ion-color-step-100) !important;
}

body.dark ion-item.bg-none,
body.dark ion-content.bg-none {
  --background: var(--ion-color-step-200) !important;
}

ion-tab-bar {
  --background: #fbfbfc !important;
}

body.dark ion-tab-bar {
  --background: var(--ion-color-step-200) !important;
}

body.dark ion-item h2,
body.dark ion-item h3 {
  color: var(--ion-color-step-850);
}

body.dark ion-item.item-has-value,
body.dark ion-item.item-has-focus {
  color: var(--ion-color-medium);
}

.ios body.dark ion-alert h2,
.ios body.dark ion-alert .alert-message {
  color: var(--ion-color-step-850);
}

.input-errors .error-msg {
  color: var(--ion-color-primary) !important;
  font-size: 12px;
}

.pageTitle {
  font-family: var(--ion-font-family, inherit);
  font-size: 22px;
  font-weight: 700;
  letter-spacing: normal;
  text-align: center;
}

body.dark .pageTitle {
  color: var(--ion-color-step-850) !important;
}

.pageSubTitle {
  font-family: var(--ion-font-family, inherit);
  font-size: 16px;
  color: rgb(204, 204, 204);
  font-weight: 700;
  letter-spacing: normal;
  text-align: center;
}

body.dark .pageSubTitle {
  color: var(--ion-color-step-700);
}

.pageSubTitleNotBold {
  font-family: var(--ion-font-family, inherit);
  font-size: 14px;
  color: rgb(204, 204, 204);
  letter-spacing: normal;
  text-align: center;
}

body.dark .pageSubTitleNotBold {
  color: var(--ion-color-step-500);
}

body.dark ion-list {
  background-color: var(--ion-color-step-200);
}

body.dark ion-picker {
  --background: var(--ion-color-step-200);
}

.border-radius {
  border-radius: 10px !important;
  overflow: hidden;
}

.popoverStart {
  --width: 250px;
}

.popoverGedachtenModal {
  --width: 100%;
  --max-height: calc(100vh - 78px);
}

.popoverGedachtenModal .popover-content {
  /*top: 20px !important;*/
}

.swiper-slide {
  font-size: 17px;
}

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
