/* eslint-disable prettier/prettier */
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import store from './store'
import router from './router'
import { auth } from './firebase'
import { openToast, openAlert } from '@/common'

import { IonicVue, isPlatform } from '@ionic/vue'

import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@capacitor/status-bar'
import { Network } from '@capacitor/network'

import { InAppPurchase2 as iap } from '@ionic-native/in-app-purchase-2'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBalanceScaleRight,
  faCheck,
  faExclamation,
  faExpandArrowsAlt,
  faFilter,
  faFolder,
  faHeart,
  faImages,
  faMale,
  faMinusCircle,
  faPlusCircle,
  faPooStorm,
  faRunning,
  faTag,
  faTimes,
  faTrash,
  faUnlockAlt,
  faUserNurse,
  faUsers,
  faYinYang,
} from '@fortawesome/free-solid-svg-icons'
import {
  faAngry,
  faFrown,
  faGrimace,
  faMehBlank,
  faTired,
  faFilePdf,
  faCopy,
  faQuestionCircle,
  faCircle,
} from '@fortawesome/free-regular-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import packageInfo from '../package.json'

library.add([
  faPooStorm,
  faYinYang,
  faHeart,
  faMinusCircle,
  faPlusCircle,
  faRunning,
  faImages,
  faUserNurse,
  faMale,
  faUsers,
  faFilter,
  faExpandArrowsAlt,
  faTag,
  faExclamation,
  faAngry,
  faFrown,
  faGrimace,
  faMehBlank,
  faTired,
  faBalanceScaleRight,
  faUnlockAlt,
  faFolder,
  faInstagram,
  faTrash,
  faFilePdf,
  faCopy,
  faQuestionCircle,
  faTimes,
  faCheck,
  faCircle,
])

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
//import "./theme/theme.css";
// if (isPlatform("ios")) {
//   import("./theme/safeArea.css").then(() => {
//     console.log("safeArea.css");
//   });
// }
// https://materialdesignicons.com/

//const crashlytics = FirebaseCrashlytics.initialise();
let app
let platform = 'unknown'
if (isPlatform('ios')) platform = 'ios'
else if (isPlatform('android')) platform = 'android'

try {
  const getCurrentNetworkStatus = async () => {
    const status = await Network.getStatus()
    if (!status.connected) {
      openToast(
        'Het lijkt erop dat je offline bent. De app werkt alleen als je online bent.',
        'danger'
      )
    }
  }
  getCurrentNetworkStatus()

  Network.addListener('networkStatusChange', (status) => {
    if (!status.connected) {
      openToast(
        'Het lijkt erop dat je offline bent. De app werkt alleen als je online bent.',
        'danger'
      )
    }
  })

  auth.onAuthStateChanged(async (user) => {
    store.dispatch('auth/setIsLoggedIn', !!user)

    if (!app) {
      app = createApp(App)
        .use(IonicVue, {
          rippleEffect: false,
          mode: 'ios',
        })
        .use(store)
        .use(router)
        .component('FontAwesomeIcon', FontAwesomeIcon)

      // iap.validator =
      //   "https://europe-west3-gedachtegrip.cloudfunctions.net/buyApp";
      // iap.validator =
      //   "https://validator.fovea.cc/v1/validate?appName=nl.kloezeict.gedachtegrip&apiKey=22ff1f1a-4631-4aae-bc32-3f13ff1377ce";
      try {
        iap.verbosity = 4

        iap.register({
          id: 'gedachte_grip_non_consumable',
          type: iap.NON_CONSUMABLE,
        })

        iap
          .when('gedachte_grip_non_consumable')
          .error((error) => {
            console.error(error)
          })
          .refunded(() => {
            //TODO: check if refunds are recorded
            store.dispatch('auth/deleteAppPaid').then(() => {
              openToast('We hebben je terug gezet naar de gratis versie')
            })
          })

        iap.refresh()
      } catch (exception) {
        if (platform === 'ios' || platform === 'android') {
          console.error(exception)
          openToast(exception, 'danger')
          //crashlytics.logException(exception);
        }
      }
    }

    if (isPlatform('android')) {
      StatusBar.setOverlaysWebView({ overlay: false })
      //StatusBar.setBackgroundColor({ color: "#f2f2f7" });
    }

    if (user) {
      if (auth.currentUser && auth.currentUser.emailVerified) {
        await store.dispatch('showLoader', 'We halen je gegevens op...')
      }

      await store.dispatch('auth/setUser', user)
      await store.dispatch('auth/getUserProfile'),
        await Promise.all([
          store.dispatch('auth/setLastLogin'),
          store.dispatch('firestore/getGedachten', user.uid),
          store.dispatch('firestore/getDankbaarheden', user.uid),
          store.dispatch('firestore/getLastGedachten', {
            userID: user.uid,
            amount: 5,
          }),
          store.dispatch('firestore/getLastDankbaarheden', {
            userID: user.uid,
            amount: 5,
          }),
          store.dispatch('firestore/getTips'),
        ])

      if (SplashScreen !== undefined) {
        SplashScreen.hide()
      }

      const userProfile = store.getters['auth/userProfile']
      const userAppVersion =
        'stats' in userProfile && 'appVersion' in userProfile.stats
          ? userProfile.stats.appVersion
          : null
      if (userAppVersion !== packageInfo.version) {
        await store.dispatch('auth/setAppVersion', packageInfo.version)
      }
      const userPlatform =
        'stats' in userProfile && 'platform' in userProfile.stats
          ? userProfile.stats.platform
          : null
      if (userPlatform !== platform) {
        await store.dispatch('auth/setAppPlatform', platform)
      }

      app.use(
        VueGtag,
        {
          appName: 'GedachteGrip',
          pageTrackerScreenviewEnabled: true,
          config: {
            id: 'G-JVQ4X2Z7JV',
            params: {
              app_name: 'GedachteGrip',
              user_id: user.uid,
              app_version: packageInfo.version,
            },
          },
        },
        router
      )
      await store.dispatch('hideLoader')
    } else {
      if (SplashScreen !== undefined) {
        SplashScreen.hide()
      }
    }
  })

  router.isReady().then(() => {
    app.mount('#app')
  })
} catch (error) {
  console.error(error)
  openToast(error, 'danger')
  //crashlytics.logException(error);
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin)

  if ((requiresAuth || requiresAdmin) && !auth.currentUser) {
    next('/login')
  } else if (requiresAdmin && !store.getters['auth/isAdmin']) {
    next('/tabs/home')
  } else if (
    auth.currentUser &&
    auth.currentUser.emailVerified &&
    (to.name == 'register' || to.name == 'forgot')
  ) {
    next('/tabs/home')
  } else if (
    auth.currentUser &&
    !auth.currentUser.emailVerified &&
    to.name != 'verify' &&
    to.name != 'login' &&
    to.name != 'forgot' &&
    to.name != 'forgotReset' &&
    to.name != 'forgotSend'
  ) {
    next('/verify')
  } else {
    next()
  }
})

CapacitorApp.addListener('appUrlOpen', (data) => {
  const url = new URL(data.url)
  const mode = url.searchParams.get('mode')
  const oobCode = url.searchParams.get('oobCode')
  //const apiKey = url.searchParams.get("apiKey");

  if (mode === 'resetPassword') {
    store.commit('auth/setOobCode', oobCode)
    router.isReady().then(() => {
      router.replace('/forgot/reset')
    })
  } else if (mode === 'verifyEmail') {
    store
      .dispatch('auth/verifyEmail', oobCode)
      .then(() => {
        router.isReady().then(() => {
          openToast('We hebben je email adres geverifieerd')
          //router.replace({ name: "login" });
        })
      })
      .catch((error) => {
        console.error(error)
        if (error.code === 'auth/expired-action-code') {
          openAlert(
            'Er is iets niet goed gegaan',
            null,
            // eslint-disable-next-line prettier/prettier
            `De link in deze email is helaas verlopen. ${
              !auth.currentUser ? 'Log in met je account en vraag' : 'Vraag'
            }  een nieuwe verificatie email aan.`
          )
        } else if (error.code === 'auth/invalid-action-code') {
          openAlert(
            'Er is iets niet goed gegaan',
            null,
            // eslint-disable-next-line prettier/prettier
            `De link in deze email is helaas niet meer geldig. ${
              !auth.currentUser ? 'Log in met je account en vraag' : 'Vraag'
            }  een nieuwe verificatie email aan.`
          )
        } else {
          openAlert('Er is iets niet goed gegaan', null, error.message)
          console.error(error)
        }
      })
  }
})
