<template>
  <svg
    viewBox="1406.305 655.14 74.2 105.851"
    width="74.2"
    height="105.851"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1469.81,296.36c-1.27-3.5-.57-7-1-10.82-.61-5-6.25-6.79-6.25-6.79s.7.83,1.2,3.83-2.39,6.91-2.39,6.91.12-9.42-4.89-13.9c-4-3.58-5.51-10.69-5.51-10.69a14.81,14.81,0,0,0-1.53,10.77,15.55,15.55,0,0,1-1.06,10.08s-.17-2.76-3.66-9.21c-2.55-4.73.08-8.81.08-12.63s-3.91-4.81-3.91-4.81a5.89,5.89,0,0,1-.29,6c-1.56,2.31-5.84,4.82-5.92,10.65a35.94,35.94,0,0,1-1.19,8.48c-.13-6.79-8.39-11.93-8.39-11.93a41.47,41.47,0,0,1,.82,12.26,19.32,19.32,0,0,1-3.78,9.29,10.81,10.81,0,0,0-.41-4.28,8.09,8.09,0,0,0-5.1-5.1s.78,11.19-3.91,20.36c-2.1,4.15-6.41,12.83-6.41,22.41a37.1,37.1,0,1,0,74.19,0C1480.46,310.79,1470.8,299.07,1469.81,296.36Zm-48.69,28.58a6.86,6.86,0,0,1,.49-2.59l-5.42-2.1,1.48-3.78,21.46,8.34-1.48,3.79-2.55-1a7.24,7.24,0,0,1-14-2.67Zm32,31.66a23.47,23.47,0,0,1-19.54,0,3.65,3.65,0,0,1-1.93-4.44l1.89-5.47a3.58,3.58,0,0,1,3.42-2.42h12.83a3.59,3.59,0,0,1,3.41,2.42l1.89,5.47A3.71,3.71,0,0,1,1453.16,356.6Zm12.46-31.66a7.24,7.24,0,0,1-14,2.63l-2.55,1-1.48-3.79,21.46-8.35,1.48,3.79-5.42,2.09A7.53,7.53,0,0,1,1465.62,324.94Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
