<template>
  <svg
    viewBox="409.263 888.17 99.141 82.93"
    width="99.141"
    height="82.93"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M444.4,504.82a35.12,35.12,0,1,0,25.51,59.24c-1.89-1.93-1.93-2-2-2l0,0h0a11.76,11.76,0,0,1,8.75-19.63,11,11,0,0,1,2.75.35c.08-.89.12-1.82.12-2.75A35.12,35.12,0,0,0,444.4,504.82ZM430.19,540.1a6,6,0,1,1,6-6A6,6,0,0,1,430.19,540.1Zm20.09,20.75a5,5,0,0,1-2.74,3,7.74,7.74,0,0,1-3.3.62,19.81,19.81,0,0,1-6-1.12v-4.07c2.64,1,6.12,1.78,7.67,1.09a1.1,1.1,0,0,0,.7-.78c.46-1.28-2.33-2.59-2.33-2.59a1.85,1.85,0,0,1-1.12-1.47,1.93,1.93,0,0,1,.62-1.75,5.23,5.23,0,0,0,1.66-2.59.91.91,0,0,0-.23-.62c-.81-.89-4.92.93-6.7,1.71l-.27.11v-4.18c3.49-1.51,7.44-2.83,9.84-.23a4.62,4.62,0,0,1,1.24,3.44,6.7,6.7,0,0,1-1.28,3.33C449.7,556.09,451.21,558.22,450.28,560.85Zm8.33-20.75a6,6,0,1,1,6-6A6,6,0,0,1,458.61,540.1Zm38.95,19.78h0l-13.44,13.75s-13.55-13.9-13.59-13.9h0a8.26,8.26,0,0,1,6.16-13.79,8.9,8.9,0,0,1,2.28.31,8.35,8.35,0,0,1,5.19,4.38,8.25,8.25,0,1,1,13.4,9.25ZM508.4,516.4a6,6,0,0,0-11.5-2.59,6,6,0,0,0-11.5,2.59,6.11,6.11,0,0,0,1.55,4.07h0c.08.11,9.95,10.18,9.95,10.18l9.83-10.07h0A6.13,6.13,0,0,0,508.4,516.4Zm-18.12-19.86a4.42,4.42,0,0,0-8.4-1.9,4.46,4.46,0,0,0-4-2.51,4.4,4.4,0,0,0-4.41,4.41,4.5,4.5,0,0,0,1.12,3l7.28,7.43L489,499.6A4.36,4.36,0,0,0,490.28,496.54Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
