<template>
  <svg
    viewBox="1544.163 1125.47 79.473 81.87"
    width="79.473"
    height="81.87"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1583.91,729.43a39.81,39.81,0,0,0-14.14,77,6.67,6.67,0,0,0,6.43,4.87h15.39a6.68,6.68,0,0,0,6.44-4.87,39.81,39.81,0,0,0-14.12-77Zm-15.61,49.35a6.86,6.86,0,0,1-6.78-5.89l13.37-2.72a6.44,6.44,0,0,1,.22,1.76A6.78,6.78,0,0,1,1568.3,778.78Zm-9.63-10.63-.87-4.25,16.58-3.4.86,4.26Zm33.47,34.37a3.14,3.14,0,0,1-3.14,3.13h-10.17a3.14,3.14,0,0,1-3.14-3.13v-3a3.14,3.14,0,0,1,3.14-3.13H1589a3.14,3.14,0,0,1,3.14,3.13Zm7.39-23.74a6.86,6.86,0,0,1-6.85-6.85,6.44,6.44,0,0,1,.23-1.76l13.37,2.72A6.83,6.83,0,0,1,1599.53,778.78Zm9.63-10.63-16.57-3.39.86-4.26,16.58,3.4Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
