<template>
  <svg
    viewBox="1108.5 673.32 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M1148.5,277.28a40,40,0,1,0,40,40A40,40,0,0,0,1148.5,277.28Zm16.92,26.43c5.19,0,9.41,3.51,9.41,7.82h-3.75c0-2.18-2.6-4.06-5.66-4.06s-5.66,1.88-5.66,4.06H1156C1156,307.22,1160.23,303.71,1165.42,303.71Zm-33.84,0c5.19,0,9.41,3.51,9.41,7.82h-3.75c0-2.18-2.6-4.06-5.66-4.06s-5.66,1.88-5.66,4.06h-3.76C1122.19,307.22,1126.41,303.71,1131.58,303.71Zm44.79,28.91-1.63-1.6a34.55,34.55,0,0,1-52.27-.19l-1.81,1.79-2.41-2.47,7.79-7.6,2.41,2.47-1.28,1.28a28.09,28.09,0,0,0,42.94.19l-1.5-1.47,2.41-2.47,7.79,7.6Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
