/* eslint-disable prettier/prettier */
import {
    toastController,
    loadingController,
    alertController
} from "@ionic/vue";

let toastVariable = null;
export function openToast(message, color = "success", duration = 2000, closeButton = false, position = 'top') {
    return new Promise((resolve, reject) => {
        closeToast();
        let options = {
            message: message,
            color: color,
            duration: duration,
            position: position,
        }
        if (closeButton) {
            options.buttons = [
                {
                    text: 'X',
                    role: 'cancel'
                }
            ]
        }

        toastController
            .create(options)
            .then((toast) => {
                toastVariable = toast;
                toast.present();
                resolve(true);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
}

export function closeToast() {
    return new Promise((resolve, reject) => {
        if (!toastVariable) resolve(true);

        toastVariable
            .dismiss()
            .then(() => {
                toastVariable = null;
                resolve(true);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
}

let loadingVariable = null;
export function openLoading(message, duration = 2000) {
    return new Promise((resolve, reject) => {
        closeLoading();
        loadingController
            .create({
                message: message,
                duration: duration,
            })
            .then((loader) => {
                loadingVariable = loader;
                loader.present();
                resolve(true);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    })
}

export function closeLoading() {
    return new Promise((resolve, reject) => {
        if (!loadingVariable) resolve(true);

        loadingVariable
            .dismiss()
            .then(() => {
                loadingVariable = null;
                resolve(true);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    })
}

let alertVariable = null;
export function openAlert(header, subHeader, message) {
    return new Promise((resolve, reject) => {
        closeAlert();
        alertController
            .create({
                header: header,
                subHeader: subHeader,
                message: message,
                buttons: [
                    {
                        text: 'Oké',
                        handler: () => resolve(true),
                    },
                ],
            })
            .then((alert) => {
                alertVariable = alert;
                alert.present();
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    })
}

export function closeAlert() {
    return new Promise((resolve, reject) => {
        if (!alertVariable) resolve(true);

        alertVariable
            .dismiss()
            .then(() => {
                alertVariable = null;
                resolve(true);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    })
}
