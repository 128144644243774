<template>
  <svg
    viewBox="2110 698.81 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2150,302.77a40,40,0,1,0,40,40A40,40,0,0,0,2150,302.77Zm-26.36,27,19.49-6.12,1.31,4.18L2125,333.87Zm4.47,12.08a6.87,6.87,0,1,1,6.87,6.87A6.85,6.85,0,0,1,2128.11,341.8Zm38.72,26.62-6-4.73-7.22,5.69-7.23-5.69-7.22,5.69-8.46-6.65,2.46-3.11,6,4.72,7.22-5.69,7.23,5.69,7.22-5.69,8.46,6.65ZM2165,348.67a6.87,6.87,0,1,1,6.87-6.87A6.85,6.85,0,0,1,2165,348.67Zm10-14.8-19.49-6.09,1.31-4.18,19.49,6.09Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
