<template>
  <svg
    version="1.1"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M 456.113 256.712 C 456.113 234.884 445.924 215.277 429.999 202.335 C 440.045 156.805 407.747 110.781 360.245 104.578 C 355.018 76.927 330.69 55.288 302.499 55.288 C 289.276 55.288 277.066 59.677 267.229 67.075 L 267.229 168.923 C 276.321 160.749 287.576 154.872 300.162 152.316 C 306.519 151.023 312.727 155.138 314.019 161.502 C 315.313 167.866 311.198 174.066 304.835 175.359 C 283.045 179.779 267.229 199.161 267.229 221.443 L 267.229 309.999 C 276.321 301.824 287.576 295.946 300.162 293.392 C 306.519 292.098 312.727 296.213 314.019 302.576 C 315.313 308.942 311.198 315.141 304.835 316.433 C 283.045 320.854 267.229 340.236 267.229 362.519 L 267.229 444.781 C 277.066 452.181 289.276 456.568 302.499 456.568 C 331.13 456.568 355.049 435.995 360.238 408.846 C 406.912 402.751 439.876 357.993 430.14 310.618 C 446.545 297.059 456.113 277.473 456.113 256.712 Z"
      style=""
    />
    <path
      fill="currentColor"
      d="M 150.701 104.578 C 103.264 110.772 70.888 156.751 80.947 202.335 C 64.664 215.568 54.833 235.36 54.833 256.712 C 54.833 277.473 64.403 297.059 80.806 310.618 C 71.062 358.033 104.071 402.756 150.71 408.846 C 155.897 435.995 179.818 456.568 208.449 456.568 C 221.67 456.568 233.881 452.181 243.717 444.781 C 243.717 427.997 243.717 379.265 243.717 362.519 C 243.717 340.236 227.901 320.854 206.113 316.433 C 199.749 315.141 195.635 308.942 196.927 302.576 C 198.22 296.213 204.42 292.098 210.784 293.392 C 223.37 295.946 234.625 301.824 243.717 309.999 L 243.717 221.443 C 243.717 199.161 227.901 179.779 206.113 175.359 C 199.749 174.066 195.635 167.866 196.927 161.502 C 198.22 155.138 204.42 151.023 210.784 152.316 C 223.37 154.872 234.625 160.749 243.717 168.923 C 243.717 148.8 243.717 87.727 243.717 67.075 C 233.881 59.677 221.67 55.288 208.449 55.288 C 180.256 55.288 155.929 76.927 150.701 104.578 Z"
      style=""
    />
  </svg>
</template>
