<template>
  <svg
    viewBox="2250.51 698.81 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M2290.51,302.77a40,40,0,1,0,40,40A40,40,0,0,0,2290.51,302.77Zm-24.23,29-3.84-2.09a16.33,16.33,0,0,1,4.84-5.22c3-2.06,8-3.84,14.61-.72l-1.87,4C2270.93,323.44,2266.47,331.43,2266.28,331.78Zm4.15,9.27a7.34,7.34,0,1,1,7.34,7.34A7.33,7.33,0,0,1,2270.43,341.05Zm35.38,20.52a33.94,33.94,0,0,0-6.75-1.13v7.21h-6v-6.93c-.13,0-.57,0-.69.06v6.87h-6.12v-4.84a18.83,18.83,0,0,0-5.68,4l-2.75-2.4c3.68-4.22,13.95-10.31,28.91-6.34Zm-1.75-13.18a7.34,7.34,0,1,1,7.34-7.34A7.33,7.33,0,0,1,2304.06,348.39Zm3.62-16.27a14.41,14.41,0,0,1-8.09-2.62l2.44-3.62a9.31,9.31,0,0,0,8.52,1.4,10.89,10.89,0,0,0,3.66-2.19l3.12,3.06A14,14,0,0,1,2307.68,332.12Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
