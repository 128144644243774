<template>
  <svg
    viewBox="513.88 1118.519 98.78 80.976"
    width="98.78"
    height="80.976"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M597.88,738.65a58.14,58.14,0,0,0-10-4.43l-5-5.64,0,0a19.55,19.55,0,0,0-14.32-6.1H557.89a19.36,19.36,0,0,0-14.67,6.49L539.43,734a56.63,56.63,0,0,0-10.73,4.63c-9.57,5.49-14.82,12.86-14.82,20.77s5.25,15.29,14.82,20.73a41.4,41.4,0,0,0,3.75,1.93,31.6,31.6,0,0,0,59.48.93,45,45,0,0,0,5.91-2.9c9.57-5.48,14.82-12.81,14.82-20.72S607.45,744.14,597.88,738.65Zm-52.11-7.56a16,16,0,0,1,12.12-5.33H568.5a16,16,0,0,1,11.85,5l1.55,1.74a80.91,80.91,0,0,0-18.65-2.13,82.81,82.81,0,0,0-18.6,2.09Zm17,61.49a17.73,17.73,0,0,1-14-6.87l3.71-2.89a13.12,13.12,0,0,0,20.69,0l3.7,2.89A17.71,17.71,0,0,1,562.75,792.58Zm33.47-15.32c-1,.54-2,1.08-3,1.58a32.13,32.13,0,0,0,.77-7c0-.81,0-1.66-.12-2.47L586,766.29a11,11,0,0,1-21.46-.65H559.2a11.06,11.06,0,0,1-21.39.93l-6.94,2.74c-.08.85-.12,1.69-.12,2.54a29.87,29.87,0,0,0,.58,5.95c-.31-.19-.66-.35-1-.54-8.49-4.86-13.16-11.2-13.16-17.87s4.67-13,13.16-17.88c8.77-5,20.46-7.75,32.93-7.75s24.17,2.77,32.93,7.75c8.49,4.87,13.16,11.2,13.16,17.88S604.71,772.43,596.22,777.26Zm-58.87-13.67-6,2.36a31.62,31.62,0,0,1,62.15.11l-7-2.74v-.27a4,4,0,0,0-4-4H568.31a4,4,0,0,0-4,3.63h-4.9a4,4,0,0,0-4-3.63H541.33a4,4,0,0,0-4,4v.35S537.35,763.52,537.35,763.59Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
