<template>
  <svg
    viewBox="404 674.38 80 80"
    width="80"
    height="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="cls-13"
      fill="currentColor"
      d="M444,278.34a40,40,0,1,0,40,40A40,40,0,0,0,444,278.34Zm-23.87,34.44a5.15,5.15,0,0,1,5.44-6.88.29.29,0,0,1,.16,0c5.6.69,9.38,4.63,10.13,6.16a19.68,19.68,0,0,0-8.5.69,19.26,19.26,0,0,0-6.1,3.13ZM444,346.68a23.67,23.67,0,0,1-21.77-14.29l5.75-2.47a17.48,17.48,0,0,0,32.09,0l5.76,2.47A23.84,23.84,0,0,1,444,346.68Zm23.86-33.9-1.12,3.09a20.49,20.49,0,0,0-6.13-3.12,19.7,19.7,0,0,0-8.51-.69c.75-1.53,4.53-5.48,10.13-6.16.06,0,.13,0,.16,0A5.17,5.17,0,0,1,467.89,312.78Z"
      transform="matrix(1, 0, 0, 1, 0, 396.040021)"
    />
  </svg>
</template>
